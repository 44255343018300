import createDoubleQuadSpacer from './createDoubleQuadSpacer'

const doubleQuad = createDoubleQuadSpacer({
  baseLength: 60,
  baseWidth: 60,
  totalHeight: 40,
  knobLength: 40,
  knobWidth: 40,
  knobHeight: 10,
  holeDiam: 6,
})

doubleQuad.label = 'double quad hole'

export default doubleQuad
