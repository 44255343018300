import { measurements } from './measurements'

const {
  raster,
  duploNibbleRadius,
  duploNibbleInnerRadius,
  duploNibbleHeight,
} = measurements

export default function topNibbles(
  { lengthUnits, widthUnits, height = Infinity },
  shapes
) {
  const duploNibbleOuterLower = shapes.cylinder({
    radius: duploNibbleRadius,
    height: duploNibbleHeight * 0.85,
  })

  const duploNibbleClear = shapes.cylinder({
    radius: duploNibbleInnerRadius,
    height: Infinity,
  })

  const posNibbleOuterLower = shapes.opTranslate({
    operands: [duploNibbleOuterLower],
    offsets: [0, 0, -(duploNibbleHeight * 0.15) / 2],
  })

  const duploNibbleOuterUpper = shapes.cylinder({
    radius: duploNibbleRadius,
    radius2: duploNibbleRadius * 0.97,
    height: duploNibbleHeight * 0.15,
    origin: [0, 0, (duploNibbleHeight * 0.85) / 2],
  })

  const duploNibble =
    height === Infinity
      ? // Just return the z-invariant "cross-section"
        shapes.opDifference({
          operands: [duploNibbleOuterLower, duploNibbleClear],
        })
      : shapes.opDifference({
          operands: [
            shapes.opUnion({
              operands: [posNibbleOuterLower, duploNibbleOuterUpper],
            }),
            duploNibbleClear,
          ],
        })

  const allNibbles = shapes.opTile({
    interval: [raster, raster, 1],
    repeats: [lengthUnits, widthUnits, 1],
    operands: [duploNibble],
    origin: [
      (raster * (1 - lengthUnits)) / 2,
      (raster * (1 - widthUnits)) / 2,
      0,
    ],
  })

  return allNibbles
}
