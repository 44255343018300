import { measurements } from './measurements'

const {
  raster,
  duploBottomNibbleRadius,
  duploBottomNibbleInnerRadius,
} = measurements

export default function bottomNibbles(
  { lengthUnits, widthUnits, height = Infinity },
  shapes
) {
  const bottomNibbleOuter = shapes.cylinder({
    radius: duploBottomNibbleRadius,
    height,
  })

  const cutout = shapes.cylinder({
    radius: duploBottomNibbleInnerRadius,
    height,
  })

  const bottomNibble = shapes.opDifference({
    operands: [bottomNibbleOuter, cutout],
  })

  const bottomNibbles = shapes.opTile({
    interval: [raster, raster, 1],
    repeats: [lengthUnits - 1, widthUnits - 1, 1],
    operands: [bottomNibble],
    origin: [raster * (1 - lengthUnits / 2), raster * (1 - widthUnits / 2), 0],
  })

  return bottomNibbles
}
