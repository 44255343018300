import createPlug from './createPlug'

const plug = createPlug({
  plugType: 'cap',
  capDiam: 26,
  roundCapHeight: 4,
  flatCapHeight: 2,
  bodyDiam: 20,
  cutoutDiam: 14,
  bodyHeight: 30,
  latchThickness: 1.5,
  slitDepth: 12,
  slitThickness: 2,
})

plug.label = 'plug'

export default plug
