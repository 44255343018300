/*
 * Double Spacer
 */
/*
 * hollow double stick
 */
import createTripleCylinder from './createTripleCylinder'

const spacer = createTripleCylinder({
  totalHeight: 40,
  innerDiam: 50,
  skirtHeight: 15,
  skirtDiam: 110,
  holeDiam: 0,
  alignBottom: true,
})

spacer.label = 'round high spacer'

export default spacer
