"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.injectMiddleware = injectMiddleware;
exports.addMiddlewares = void 0;

var _origin = _interopRequireDefault(require("../middleware/origin"));

var _angledegree = _interopRequireDefault(require("../middleware/angledegree"));

var _connectors = _interopRequireDefault(require("../middleware/connectors"));

var _util = require("../util");

var _isEqual = _interopRequireDefault(require("lodash/isEqual"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

/*
 * configure overloads params before user-specified params are added.
 */
function defaultConfigure(params) {
  return params;
}
/*
 * evaluate pre-processes space before evaluating.
 */


function defaultEvaluate(point, params) {
  return point;
}

var modifiers = {};
/*
 * Add middleware to the modifiers. This is dynamic, i.e. has an effect on
 * expressions previously added to library too.
 * Middleware coperaiontontract: (nextModifiers) => (chainedModifiers).
 * I.e. put additional logic in a new modifier and call nextModifiers last.
 */

var addMiddlewares = function addMiddlewares() {
  var middlewares = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  // Start with current modifiers.
  var currentConfigure = defaultConfigure;
  var currentEvaluate = defaultEvaluate; // For all (reversed) middlewares, chain them together and add them back
  // to modifiers.
  // Note: We don't ever re-assign the modifiers variable itself, because
  // expressions will point to the modifiers object and we can add middleware
  // after injecting middleware into expressions.

  middlewares.slice().reverse().forEach(function (_ref) {
    var configure = _ref.configure,
        evaluate = _ref.evaluate;

    if (configure) {
      currentConfigure = configure(currentConfigure);
    }

    if (evaluate) {
      currentEvaluate = evaluate(currentEvaluate);
    } // A simple check for configure chain. (Evaluate chain check requires a
    // THREE dependency)


    var test = {},
        expr = function expr() {
      return function () {};
    };

    function configureNextTest(p, e) {
      if (_typeof(p) !== 'object') {
        throw new Error('middleware configure must call next with an object');
      }
    }

    configure(configureNextTest)(test, expr);
  }); // Finally, replace modifiers with new, chained ones.
  // We do not re-assign the modifiers variable itself, to still have
  // expressions point to the existing modifiers object.

  modifiers.configure = currentConfigure;
  modifiers.evaluate = currentEvaluate;
};

exports.addMiddlewares = addMiddlewares;

function injectMiddleware(expression) {
  var withMiddleware = function withMiddleware() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    // Here, we ensure that original params is not modified.
    // Else we pollute our caller's param object, and they may re-use it later.
    // Example: Before this, we converted the caller's "origin"
    // param into a Three vector in place, which failed next time the
    // caller provided us with a param object.
    params = modifiers.configure(_objectSpread({}, params), expression);
    var configured = expression(params);

    var final = function final(point) {
      return configured(modifiers.evaluate(point, params));
    }; // Add params to function so middleware can access it (see connectors).
    // TODO: We might get rid of this and have specific .connectors prop.
    // If not, rename to "final.params"
    // TODO: Ok, got rid of it for now.
    // final.options = params


    return final;
  };

  (0, _util.copyOwnProperties)(expression, withMiddleware);
  return withMiddleware;
} // Add default middleware.


addMiddlewares([_origin.default, _angledegree.default, _connectors.default]);