"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _three = require("three");

var _vector = _interopRequireDefault(require("../vector3"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

function _construct(Parent, args, Class) { if (isNativeReflectConstruct()) { _construct = Reflect.construct; } else { _construct = function _construct(Parent, args, Class) { var a = [null]; a.push.apply(a, args); var Constructor = Function.bind.apply(Parent, a); var instance = new Constructor(); if (Class) _setPrototypeOf(instance, Class.prototype); return instance; }; } return _construct.apply(null, arguments); }

function _setPrototypeOf(o, p) { _setPrototypeOf = Object.setPrototypeOf || function _setPrototypeOf(o, p) { o.__proto__ = p; return o; }; return _setPrototypeOf(o, p); }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

/*
 * Build a connector from a declaration.
 */
function buildConnector(rawConnector) {
  var point = rawConnector.point,
      axis = rawConnector.axis,
      normal = rawConnector.normal;
  var connector = {
    // The stud's location
    point: _construct(_vector.default, _toConsumableArray(point)),
    // Direction of the stud.
    axis: _construct(_vector.default, _toConsumableArray(axis)),
    // Define the attached object's rotation around the stud.
    normal: _construct(_vector.default, _toConsumableArray(normal))
  }; // Ensure that normal is orthogonal to axis.

  connector.normal.projectOnPlane(connector.axis); // This fails if normal and axis are parallel.

  if (connector.normal.length() < 1e-7) {
    throw new Error('Bad connector definition: axis and normal cannot be parallel.');
  }

  connector.axis.normalize();
  connector.normal.normalize();
  return connector;
}
/*
 * Given two connectors, return the connecting matrix.
 */


function getMatrix(cFrom, cTo) {
  var _ref, _ref2;

  // Align old axis with new axis.
  var qAxisRotation = new _three.Quaternion().setFromUnitVectors(cFrom.axis, cTo.axis);
  var axisRotation = new _three.Matrix4().makeRotationFromQuaternion(qAxisRotation); // Rotate to align normals.
  // Apply axisRotation to figure out where "from" normal lands.

  var newFromNormal = cFrom.normal.clone().applyMatrix4(axisRotation); // Now, need to align that direction with the "to" normal.
  // The direction of the angle is only defined in reference to the axis.

  var angleSign = Math.sign(newFromNormal.clone().cross(cTo.normal).dot(cTo.axis));
  var normalRotAngle = angleSign * cTo.normal.angleTo(newFromNormal);
  var normalRotation = new _three.Matrix4().makeRotationAxis(cTo.axis, normalRotAngle); // The complete rotation, based at origin, therefore is
  // normalRotation * axisRotation
  // To finalize, need to move objects point to origin, transform, move back.

  var fromTranslateInv = (_ref = new _three.Matrix4()).makeTranslation.apply(_ref, _toConsumableArray(cFrom.point.clone().negate().toArray()));

  var toTranslate = (_ref2 = new _three.Matrix4()).makeTranslation.apply(_ref2, _toConsumableArray(cTo.point.toArray())); // The final transformation goes into connect.


  var connect = new _three.Matrix4().multiply(toTranslate).multiply(normalRotation).multiply(axisRotation).multiply(fromTranslateInv);
  return connect;
}

function orientedEvaluate(operand, refConnectors) {
  var cFrom = buildConnector(operand.expression.connectors[operand.opConnector]);
  var cTo = buildConnector(refConnectors[operand.attachTo]);
  var matrix = getMatrix(cFrom, cTo); // Instead of a true operand function, return a function
  // that will evaluate after orienting.

  return function (point) {
    return operand.expression(point.clone().applyMatrix4(matrix));
  };
}

var _default = {
  configure: function configure(next) {
    return function (params) {
      if (params.connect) {
        var connect = params.connect;

        var _connect = _slicedToArray(connect, 3),
            rawFrom = _connect[0],
            rawTo = _connect[1],
            _connect$ = _connect[2],
            options = _connect$ === void 0 ? {} : _connect$;

        var cFrom = buildConnector(rawFrom);
        var cTo = buildConnector(rawTo);

        if (options.mirror) {
          cTo.axis.negate();
        }

        var matrix = getMatrix(cFrom, cTo); // This feels like a hack. Probably configure() should return an expression
        // to which I can pass updated params, and I shouldn't mess with params.

        params._connectMatrix = matrix;
      }

      return next(params);
    };
  },
  evaluate: function evaluate(next) {
    return function (point, params) {
      var aligned = point;

      if (params._connectMatrix) {
        aligned = point.clone().applyMatrix4(params._connectMatrix);
      }

      return next(aligned, params);
    };
  }
};
exports.default = _default;