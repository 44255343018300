/*
 * Build a key-to-expression map
 */
import * as expressions from './expressions'

export default function useDefaultExpressions() {
  const expressionMap = Object.keys(expressions).map((key) => {
    const match = key.match(/expr_(.{4})/)
    if (match == null) {
      return null
    }
    const id = match[1]
    return { [id]: expressions[key] }
  })

  return Object.assign({}, ...expressionMap)
}
