"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.moveBounds = moveBounds;
exports.modifyBounds = modifyBounds;
exports.maxBounds = maxBounds;

var _vector = _interopRequireDefault(require("../vector3"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

function _construct(Parent, args, Class) { if (isNativeReflectConstruct()) { _construct = Reflect.construct; } else { _construct = function _construct(Parent, args, Class) { var a = [null]; a.push.apply(a, args); var Constructor = Function.bind.apply(Parent, a); var instance = new Constructor(); if (Class) _setPrototypeOf(instance, Class.prototype); return instance; }; } return _construct.apply(null, arguments); }

function _setPrototypeOf(o, p) { _setPrototypeOf = Object.setPrototypeOf || function _setPrototypeOf(o, p) { o.__proto__ = p; return o; }; return _setPrototypeOf(o, p); }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

// Manipulate bounds
function moveBounds(getBounds, v) {
  var vector = _construct(_vector.default, _toConsumableArray(v));

  return function (params) {
    var _getBounds = getBounds(params),
        min = _getBounds.min,
        max = _getBounds.max;

    var vMin = _construct(_vector.default, _toConsumableArray(min));

    var vMax = _construct(_vector.default, _toConsumableArray(max));

    vMin.add(vector);
    vMax.add(vector);
    return {
      min: [vMin.x, vMin.y, vMin.z],
      max: [vMax.x, vMax.y, vMax.z]
    };
  };
}

function modifyBounds(getBounds, fn) {
  return function (params) {
    return fn(getBounds(params));
  };
}

function maxBounds() {
  for (var _len = arguments.length, getBoundsArr = new Array(_len), _key = 0; _key < _len; _key++) {
    getBoundsArr[_key] = arguments[_key];
  }

  return function (params) {
    var boundsArr = getBoundsArr.map(function (getBounds) {
      return getBounds(params);
    });
    var vMin = new _vector.default(Infinity, Infinity, Infinity);
    var vMax = new _vector.default(-Infinity, -Infinity, -Infinity);
    boundsArr.forEach(function (_ref) {
      var min = _ref.min,
          max = _ref.max;
      vMin.min(_construct(_vector.default, _toConsumableArray(min)));
      vMax.max(_construct(_vector.default, _toConsumableArray(max)));
    });
    return {
      min: vMin.toArray(),
      max: vMax.toArray()
    };
  };
}