"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  runners: true,
  registerRunner: true,
  add: true,
  visitors: true
};
Object.defineProperty(exports, "runners", {
  enumerable: true,
  get: function get() {
    return _runners.runners;
  }
});
Object.defineProperty(exports, "registerRunner", {
  enumerable: true,
  get: function get() {
    return _runners.registerRunner;
  }
});
Object.defineProperty(exports, "add", {
  enumerable: true,
  get: function get() {
    return _expressions.default;
  }
});
Object.defineProperty(exports, "visitors", {
  enumerable: true,
  get: function get() {
    return _visitors.default;
  }
});

var _threegeometry = _interopRequireDefault(require("./engines/threegeometry"));

var _runners = require("./runners");

var _expressions = _interopRequireDefault(require("./expressions"));

var _visitors = _interopRequireDefault(require("./visitors"));

var _util = require("./util");

Object.keys(_util).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _util[key];
    }
  });
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/*
 * This is the cvg interface.
 */
//
// Built-in runner engines
//
// import saveNrrd from './engines/savenrrd';
// import saveStl from './engines/savestl';
//
// Add built-in runners
//
(0, _runners.registerRunner)({
  threeGeometry: _threegeometry.default // saveNrrd,
  // saveStl

}); // This is the API.
// TODO: Can we have middleware that allows us to specify
// arbitrary values (i.e., part dimensions) for shapes that
// have been fed with parameters? Example:
// Lego part has dim = parameters.length * constant.unitlength;