"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _three = require("three");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

var toRadian = function toRadian(degree) {
  return _three.Math.degToRad(degree);
};

var _default = {
  configure: function configure(next) {
    return function (params) {
      var hasAngleDeg = typeof params.angleDeg !== 'undefined';
      var hasAngleRad = typeof params.angleRad !== 'undefined';
      var hasAngle = typeof params.angle !== 'undefined';

      if (!hasAngle) {
        if (hasAngleDeg) {
          var angleDeg = params.angleDeg,
              rest = _objectWithoutProperties(params, ["angleDeg"]);

          return next(_objectSpread({}, rest, {
            angle: toRadian(angleDeg)
          }));
        }

        if (hasAngleRad) {
          var angleRad = params.angleRad,
              _rest = _objectWithoutProperties(params, ["angleRad"]);

          return next(_objectSpread({}, _rest, {
            angle: angleRad
          }));
        }
      }

      return next(params);
    };
  }
};
exports.default = _default;