import { createCurvedDuplo } from './createDuplo'

const curvedDuplo = createCurvedDuplo({
  lengthUnits: 2,
  widthUnits: 2,
  heightUnits: 0.5,
  gyroidize: false,
})

export default curvedDuplo
