"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _grid = _interopRequireDefault(require("./grid"));

var _rastertosample = _interopRequireDefault(require("./rastertosample"));

var _raster = _interopRequireDefault(require("./gridexpressions/raster"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/*
 * Lazy rasterize, using the raster grid expression.
 * Input: user expression and grid options
 * Output: raster expression and grid instance.
 */
var rasterize = function rasterize(expression, params, gridOptions) {
  if (expression.bounds) {
    gridOptions = Object.assign({}, gridOptions, {
      bounds: expression.bounds(params)
    });
  }

  var grid = new _grid.default(gridOptions);
  var pointExpression = expression(params);
  return {
    grid: grid,
    raster: (0, _raster.default)({
      operands: [pointExpression],
      grid: grid,
      sampleGrid: (0, _rastertosample.default)(grid)
    })
  };
};

var _default = rasterize;
exports.default = _default;