"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _cache = _interopRequireDefault(require("./cache"));

var _generalsample = _interopRequireDefault(require("./generalsample"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/*
 * Raster expression including sampling and caching.
 */

/*
 * The raster expression samples and rasterizes.
 * It knows how to extend a grid for sampling.
 * The sampling level is always cached (8 times look up).
 * The final raster cache can be enabled/disabled
 * E.g. not necessary on saving nrrd, but 8 times look up on marching cubes.
 * It is enabled by default.
 *
 * NOTE: Using less precision than Float64Array in the cache leads to
 * missing faces on marching cubes with resolution 0.2, box with dim 0.6,
 * on chrome 52.0.2743.116 64bit).
 */
var raster = function raster(_ref) {
  var grid = _ref.grid,
      sampleGrid = _ref.sampleGrid,
      operands = _ref.operands,
      _ref$cached = _ref.cached,
      cached = _ref$cached === void 0 ? true : _ref$cached;
  var sample = (0, _generalsample.default)({
    grid: sampleGrid,
    operands: operands,
    gridOperands: false,
    average8: false
  }); // We cache the samples in any case as they're looked up 8 times.

  var cachedSample = (0, _cache.default)({
    dataType: Float64Array,
    grid: sampleGrid,
    operands: [sample]
  }); // Average 8 surrounding samples for calculating voxel.

  var raster = (0, _generalsample.default)({
    grid: grid,
    operands: [cachedSample],
    gridOperands: true,
    average8: true
  }); // Add optional caching.

  var finalRaster = cached ? (0, _cache.default)({
    dataType: Float64Array,
    grid: grid,
    operands: [raster]
  }) : raster;
  return function (gridLoc) {
    return finalRaster(gridLoc);
  };
};

var _default = raster;
exports.default = _default;