import createNotchedRing from './createNotchedRing'

const ring = createNotchedRing({
  ringDiam: 60,
  ringHeight: 100,
  ringThickness: 10,
  notchDiam: 0,
  notchWidth: 0,
  notchHeight: 0,
})

ring.label = 'tube'

const { ringDiam, ringHeight, ringThickness } = ring.params
ring.params = { ringDiam, ringHeight, ringThickness }

export default ring
