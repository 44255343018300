const brioHeight = 12
const brioPlugRadius = 5.75

const neckWidth = 6

const trackWidth = 40

const grooveHeight = 4
const grooveWidthLow = 5
const grooveWidthHigh = 8
const trackGauge = 26

const partThickness = 1.5

// Using this for neckWidth but also parametrized neckLength.
const tolerance = 1

const invNeckWidth = neckWidth + tolerance
const invBrioPlugRadius = brioPlugRadius + tolerance / 2

export const measurements = {
  partThickness,
}

const defaultNeckLength = 6

function createPlug({ neckLength, neckWidth, height, plugRadius }, shapes) {
  // We'll connect stuff here.
  const plugNeck = shapes.box({
    // Enlarge the length by plugRadius so we land in the plug head center.
    dimensions: [neckLength + plugRadius, neckWidth, height],
    // Simplify bounds calc
    origin: [(neckLength + plugRadius) / 2, 0, 0],
  })

  // connectors at the neck: the brio track side and the plugHead side.
  const plugNeckConnectors = {
    track: {
      point: [0, 0, 0],
      axis: [1, 0, 0],
      normal: [0, 0, 1],
    },
    plugHead: {
      point: [neckLength + plugRadius, 0, 0],
      axis: [-1, 0, 0],
      normal: [0, 0, 1],
    },
  }

  const plugHeadConnector = {
    point: [0, 0, 0],
    axis: [1, 0, 0],
    normal: [0, 0, 1],
  }
  const plugHead = shapes.cylinder({
    radius: plugRadius,
    height: height,
    connect: [plugNeckConnectors.plugHead, plugHeadConnector],
  })

  const plug = shapes.opUnion({ operands: [plugHead, plugNeck] })
  plug.connector = plugNeckConnectors.track

  return plug
}

export default function createBrio(defaults) {
  function brio(
    { trackLength = defaults.trackLength, neckLength = defaultNeckLength } = {},
    shapes
  ) {
    const plug = createPlug(
      {
        neckLength,
        neckWidth,
        height: brioHeight + partThickness,
        plugRadius: brioPlugRadius,
      },
      shapes
    )

    const trackBase = shapes.box({
      dimensions: [trackLength, trackWidth, brioHeight + partThickness],
    })

    function makeGroove({ origin }) {
      const grooveTrapez = shapes.trapezoid({
        lowDimensions: [trackLength, grooveWidthLow],
        highDimensions: [trackLength, grooveWidthHigh],
        height: grooveHeight / 2,
        origin: [0, 0, grooveHeight / 4],
      })

      const grooveBox = shapes.box({
        dimensions: [trackLength, grooveWidthLow, grooveHeight / 2],
        origin: [0, 0, -grooveHeight / 4],
      })

      return shapes.opUnion({
        operands: [grooveTrapez, grooveBox],
        origin,
      })
    }

    const leftGroove = makeGroove({
      origin: [
        0,
        -trackGauge / 2,
        (brioHeight + partThickness - grooveHeight) / 2,
      ],
    })

    const rightGroove = makeGroove({
      origin: [
        0,
        trackGauge / 2,
        (brioHeight + partThickness - grooveHeight) / 2,
      ],
    })

    const trackConnectors = {
      front: {
        point: [trackLength / 2, 0, 0],
        axis: [1, 0, 0],
        normal: [0, 0, 1],
      },
      back: {
        point: [-trackLength / 2, 0, 0],
        axis: [-1, 0, 0],
        normal: [0, 0, 1],
      },
    }

    const plugCutout = createPlug(
      {
        // cutout lengths
        neckLength: neckLength - tolerance,
        neckWidth: invNeckWidth,
        height: brioHeight + partThickness,
        plugRadius: invBrioPlugRadius,
      },
      shapes
    )

    const alignedPlugCutout = shapes.opIdentity({
      operands: [plugCutout],
      connect: [trackConnectors.back, plugCutout.connector, { mirror: true }],
    })

    const track = shapes.opDifference({
      operands: [trackBase, leftGroove, rightGroove, alignedPlugCutout],
      connect: [plug.connector, trackConnectors.front],
    })

    const solidBrio = shapes.opUnion({
      operands: [track, plug],
      // We made the brio higher by partThickness - adjusting now.
      origin: [0, 0, -partThickness / 2],
    })

    // And then, we can hollow it and cut the bottom
    const hollowBrio = shapes.opHollow({
      operands: [solidBrio],
      thickness: partThickness,
    })

    const hollowCutBrio = shapes.opCut({
      operands: [hollowBrio],
      point: [0, 0, -brioHeight / 2],
    })

    const lowBase = shapes.box({
      dimensions: [
        trackLength - 2 * partThickness,
        trackWidth - 2 * partThickness,
        partThickness,
      ],
      origin: [0, 0, (-brioHeight + partThickness) / 2],
      connect: [plug.connector, trackConnectors.front],
    })

    const brio = shapes.opIdentity({
      operands: [hollowCutBrio /*, lowBase*/],
      // center the final piece.
      origin: [(trackLength - neckLength - 2 * brioPlugRadius) / 2, 0, 0],
    })

    return brio
  }

  brio.params = {
    trackLength: {
      type: 'number',
      title: 'track length',
      exclusiveMinimum: 0,
      validate: ({ trackLength, neckLength }) => {
        const invNeckLength = neckLength - tolerance
        const plugLen = invNeckLength + 2 * invBrioPlugRadius
        // The 1/6 is just by observation of what went well with partThickness 1.5mm
        const minLen = plugLen + (partThickness * 1) / 6
        if (trackLength < minLen) {
          return `>= ${minLen}`
        }
      },
      default: defaults.trackLength,
    },
    neckLength: {
      type: 'number',
      title: 'connector neck length',
      exclusiveMinimum: 0,
      maximum: 12,
      default: defaultNeckLength,
    },
  }

  brio.bounds = ({ trackLength, neckLength = defaultNeckLength }) => {
    const length = trackLength + neckLength + 2 * brioPlugRadius

    return {
      min: [-length / 2, -trackWidth / 2, -brioHeight / 2],
      max: [length / 2, trackWidth / 2, brioHeight / 2],
    }
  }

  return brio
}
