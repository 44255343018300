"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

/*
 * Expression primitives.
 */
var expressions = {}; // Maybe a simpler approach: just rotate a tube around a cylinder,
// then bend (local translate) along a sphere.

expressions.loxodrome = function (_ref) {
  var _ref$radius = _ref.radius,
      radius = _ref$radius === void 0 ? 4 : _ref$radius,
      _ref$tube = _ref.tube,
      tube = _ref$tube === void 0 ? 1 : _ref$tube,
      _ref$slope = _ref.slope,
      slope = _ref$slope === void 0 ? 0.5 : _ref$slope;
  return function (point) {
    // let { radius, phi, theta } = this.vector3ToSpherical(point);
    var _point$getSpherical = point.getSpherical(),
        r = _point$getSpherical.radius,
        phi = _point$getSpherical.phi,
        theta = _point$getSpherical.theta; // Make theta go from -Pi/2 ([0, 0, -1]) to Pi/2 ([0, 0, 1]).


    theta = Math.PI / 2 - theta;
    var dir = Math.sign(theta);
    var i = 0; // theta == 0 -> dir = 0 -> while breaks

    while (dir * theta > dir * slope * (2 * Math.PI * i + phi)) {
      i += dir;
    } // now, theta is between theta0 and theta1


    var theta0 = Math.asin(Math.tanh(slope * (phi + 2 * Math.PI * (i - dir))));
    var theta1 = Math.asin(Math.tanh(slope * (phi + 2 * Math.PI * i)));
    var x = r * Math.cos(theta),
        y = r * Math.sin(theta);
    var x0 = radius * Math.cos(theta0),
        y0 = radius * Math.sin(theta0);
    var x1 = radius * Math.cos(theta1),
        y1 = radius * Math.sin(theta1); // this will be replaced by operand check

    return Math.min((x - x0) * (x - x0) + (y - y0) * (y - y0) - tube * tube, (x - x1) * (x - x1) + (y - y1) * (y - y1) - tube * tube);
  };
};

var _default = expressions; // While we could do that, this shape has no bounds and we'd rather make it
// an intersection operation... except... if we make bezeled intersections and the like?
// expressions.gyroid = ({ frequency = 4, amplitude = 1 }) => point => {
//   const f = frequency
//   const gyr =
//     Math.cos(f * point.x) * Math.sin(f * point.y) +
//     Math.cos(f * point.y) * Math.sin(f * point.z) +
//     Math.cos(f * point.z) * Math.sin(f * point.x)
//   return amplitude * gyr
// }

exports.default = _default;