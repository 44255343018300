"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var expressions = {};

var flower = function flower(x, y, z, _ref) {
  var quad = _ref.quad,
      f = _ref.f,
      offset = _ref.offset,
      leafPairs = _ref.leafPairs;
  var stepz = 0.8;
  var crossShape = quad ? Math.pow(Math.pow(x, 4) + Math.pow(y, 4), 0.25) : Math.sqrt(x * x + y * y);
  return -Math.PI * 2 + Math.cos((Math.atan(y / x) + Math.sin(z / f + offset)) * leafPairs * 2) + (0.6 + stepz) * crossShape;
};

var piriRadius = function piriRadius(a, b, z) {
  return Math.sqrt(b * b * Math.pow(z, 3) * (2 * a - z) / Math.pow(a, 4));
};

expressions.piriform = function (_ref2) {
  var _ref2$a = _ref2.a,
      a = _ref2$a === void 0 ? 30 : _ref2$a,
      _ref2$b = _ref2.b,
      b = _ref2$b === void 0 ? 5 : _ref2$b;
  return function (point) {
    return point.length() - piriRadius(a, b, point.z);
  };
};

expressions.softIce = function (_ref3) {
  var _ref3$quad = _ref3.quad,
      quad = _ref3$quad === void 0 ? false : _ref3$quad,
      _ref3$f = _ref3.f,
      f = _ref3$f === void 0 ? 13 : _ref3$f,
      _ref3$offset = _ref3.offset,
      offset = _ref3$offset === void 0 ? 0 : _ref3$offset,
      _ref3$leafPairs = _ref3.leafPairs,
      leafPairs = _ref3$leafPairs === void 0 ? 0 : _ref3$leafPairs,
      _ref3$a = _ref3.a,
      a = _ref3$a === void 0 ? 35 : _ref3$a,
      _ref3$b = _ref3.b,
      b = _ref3$b === void 0 ? 45 : _ref3$b;
  return function (point) {
    var x = point.x,
        y = point.y,
        z = point.z;
    var endHeight = 2 * a;
    var zn = endHeight - z;
    var r = piriRadius(a, b, z);
    var xs = x / r,
        ys = y / r;
    return flower(xs, ys, z, {
      quad: quad,
      f: f,
      offset: offset,
      leafPairs: leafPairs
    });
  };
};

var _default = expressions;
exports.default = _default;