import { useMemo, useCallback } from 'react'
import useDefaultThumbnails from './useDefaultThumbnails'
import useDefaultExpressions from './useDefaultExpressions'
import { useStore } from './storeContext'

const sequence = [
  'qoxz',
  'tsml',
  'uwci',
  'r5ko',
  'p8p1',
  '4qif',
  '4ej2',
  'jcwh',
  'ofkt',
  'olnv',
  '5xuv',
  'iahd',
  'oa3a',
  'xa8u',
  '2fhj',
  'rv0y',
  'gk8u',
  'k036',
  '5erm',
  'asr7',
  'rdi1',
  '1rd0',
  'd9sr',
  'cc1h',
]

// No need to ask benefit in survey
export const toyIds = ['d9sr', 'cc1h']

const toFixed = (x) => x
const toExpression = (x) => x

const paramsFromDefinition = (params = {}) =>
  Object.assign(
    {},
    ...Object.keys(params).map((p) => ({
      [p]: params[p].default,
    }))
  )

const empty = []

export default function useBackend(itemId) {
  const thumbnails = useDefaultThumbnails()
  const expressions = useDefaultExpressions()
  const { data, mergeData, loading: storeLoading } = useStore()

  const allItems = useMemo(() => {
    // store needs time to load
    if (storeLoading) {
      return empty
    }

    return sequence.map((id) => {
      const result = {
        id,
        label: expressions[id]?.label ?? expressions[id]?.name,
        fixed: thumbnails[id],
        expression: expressions[id],
        params: paramsFromDefinition(expressions[id]?.params),
      }

      const { base64, str, params } = data[id] || {}

      if (base64) {
        result.fixed = toFixed(base64)
      }
      if (str) {
        result.expression = toExpression(str)
      }

      if (params) {
        result.params = params
      }

      return result
    })
  }, [storeLoading, thumbnails, expressions, data])

  const index = useMemo(() => {
    if (itemId == null) {
      return -1
    }

    return sequence.indexOf(itemId)
  }, [itemId])

  const item = itemId == null || index === -1 ? null : allItems[index]

  const replaceParams = useCallback(
    (itemId, params) => {
      mergeData(itemId, { params })
    },
    [mergeData]
  )

  return {
    allItems,
    index,
    item,
    replaceParams,
    loading: storeLoading,
  }
}
