import createNotchedRing from './createNotchedRing'

const slitRing = createNotchedRing({
  ringDiam: 100,
  ringHeight: 20,
  ringThickness: 10,
  notchDiam: null,
  notchWidth: 10,
  notchHeight: 20,
})

slitRing.label = 'split ring'

const { ringDiam, ringHeight, ringThickness, notchWidth } = slitRing.params

slitRing.params = { ringDiam, ringHeight, ringThickness, notchWidth }

export default slitRing
