"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

/*
 * Caching expression to speed up look ups to the same grid location.
 */
var cache = function cache(_ref) {
  var grid = _ref.grid,
      operands = _ref.operands,
      dataType = _ref.dataType;
  var cache = new dataType(grid.size());
  return function (gridPoint) {
    var rasterOffset = grid.offset(gridPoint);
    var value = cache[rasterOffset]; // Note: Since most dataTypes are initialized with zeros, zero values
    // will be evaluated everytime, which should be sufficiently rare.

    if (!value) {
      value = operands[0](gridPoint);
      cache[rasterOffset] = value;
    }

    return value;
  };
};

var _default = cache;
exports.default = _default;