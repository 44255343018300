import createSupportedRing from './createSupportedRing'

const knob = createSupportedRing({
  lidDiam: 110,
  lidHeight: 4,
  ringOffset: 10,
  ringHeight: 50,
  ringThickness: 30,
})

knob.label = 'knob'

export default knob
