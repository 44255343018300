export default function createTripleCylinder(defaults) {
  /*
   * hollow double main
   */
  function tripleCylinder(
    {
      innerDiam = defaults.innerDiam,
      totalHeight = defaults.totalHeight,
      skirtDiam = defaults.skirtDiam,
      skirtHeight = defaults.skirtHeight,
      holeDiam = defaults.holeDiam,
      alignBottom = defaults.alignBottom,
    },
    shapes
  ) {
    const main = shapes.cylinder({
      radius: innerDiam / 2,
      height: totalHeight,
    })

    const skirtOrigin = alignBottom
      ? [0, 0, (skirtHeight - totalHeight) / 2]
      : undefined
    const skirt = shapes.cylinder({
      radius: skirtDiam / 2,
      height: skirtHeight,
      origin: skirtOrigin,
    })
    const hole = shapes.cylinder({
      radius: holeDiam / 2,
      height: Math.max(totalHeight, skirtHeight),
    })
    const stickSkirt = shapes.opUnion({ operands: [main, skirt] })
    return shapes.opDifference({ operands: [stickSkirt, hole] })
  }

  tripleCylinder.label = 'double main'

  tripleCylinder.params = {
    totalHeight: {
      title: 'total height',
      type: 'number',
      exclusiveMinimum: 0,
      maximum: 110,
      default: defaults.totalHeight,
    },
    innerDiam: {
      type: 'number',
      title: 'inner diameter',
      exclusiveMinimum: 0,
      maximum: 110,
      default: defaults.innerDiam,
    },
    skirtHeight: {
      title: 'skirt height',
      type: 'number',
      exclusiveMinimum: 0,
      maximum: 110,
      default: defaults.skirtHeight,
      validate: ({ totalHeight, skirtHeight }) => {
        if (totalHeight < skirtHeight) {
          return `< ${totalHeight}`
        }
      },
    },
    skirtDiam: {
      type: 'number',
      title: 'skirt diameter',
      exclusiveMinimum: 0,
      maximum: 110,
      default: defaults.skirtDiam,
      validate: ({ innerDiam, skirtDiam }) => {
        if (skirtDiam < innerDiam) {
          return `>= ${innerDiam}`
        }
      },
    },
    holeDiam: {
      type: 'number',
      title: 'hole diameter',
      minimum: 0,
      maximum: 110,
      default: defaults.holeDiam,
      validate: ({ innerDiam, holeDiam }) => {
        if (holeDiam >= innerDiam) {
          return `< ${innerDiam}`
        }
      },
    },
  }

  tripleCylinder.bounds = ({
    totalHeight,
    skirtHeight,
    innerDiam,
    skirtDiam,
  }) => {
    const height = Math.max(totalHeight, skirtHeight)
    const width = Math.max(innerDiam, skirtDiam)
    return {
      min: [-width / 2, -width / 2, -height / 2],
      max: [width / 2, width / 2, height / 2],
    }
  }

  return tripleCylinder
}
