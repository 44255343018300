/*
 * CommonJS notation because gatsby-node also accesses this.
 */

const HOME = '/'
const PAGE_404 = '/404'
const THING = '/thing'
const SHOP_FEEDBACK = '/shopfeedback'

const thingRoute = (thingId) => `${THING}/${thingId}`

module.exports = {
  HOME,
  PAGE_404,
  THING,
  SHOP_FEEDBACK,
  thingRoute,
}
