// Note: notchDiam and ringThickness don't NEED to be defined. The others, better define.
// But different notchWidth/notchHeight doesn't work with notchIsRound

function isDef(arg) {
  return typeof arg === 'number' || typeof arg === 'boolean'
}

function getSegmentHeight(ringDiam, segmentLength) {
  return (
    ringDiam / 2 - 0.5 * Math.sqrt(4 * (ringDiam / 2) ** 2 - segmentLength ** 2)
  )
}

/*
 * General ring segment
 */
export default function createRingSegment(defaults) {
  function ringSegment(
    {
      ringDiam = defaults.ringDiam,
      ringHeight = defaults.ringHeight,
      ringThickness = defaults.ringThickness,
      segmentLength = defaults.segmentLength,
    } = {},
    shapes
  ) {
    // This becomes a disk with no hole if not defined.
    ringThickness = isDef(ringThickness) ? ringThickness : ringDiam / 2

    const segmentHeight = getSegmentHeight(ringDiam, segmentLength)

    const ringOuter = shapes.cylinder({
      radius: ringDiam / 2,
      height: ringHeight,
    })

    const ringInner = shapes.cylinder({
      radius: ringDiam / 2 - ringThickness,
      height: ringHeight,
    })

    const limitCube1 = shapes.box({
      dimensions: [ringDiam, ringDiam, ringHeight],
      origin: [0, (ringDiam + segmentLength) / 2, 0],
    })

    const limitCube2 = shapes.box({
      dimensions: [ringDiam, ringDiam, ringHeight],
      origin: [0, -(ringDiam + segmentLength) / 2, 0],
    })

    // if it's a disk, nothing to subtract here.
    const ring =
      ringDiam / 2 > ringThickness
        ? shapes.opDifference({ operands: [ringOuter, ringInner] })
        : ringOuter

    const finalRing = shapes.opDifference({
      operands: [ring, limitCube1, limitCube2],
      origin: [ringDiam / 2 - segmentHeight, 0, 0],
    })

    return finalRing
  }

  ringSegment.params = {
    ringDiam: {
      type: 'number',
      title: 'ring diameter',
      exclusiveMinimum: 0,
      default: defaults.ringDiam,
    },
    ringHeight: {
      type: 'number',
      title: 'ring thickness',
      exclusiveMinimum: 0,
      default: defaults.ringHeight,
    },
    ringThickness: {
      type: 'number',
      title: 'ring width',
      exclusiveMinimum: 0,
      default: defaults.ringThickness,
      validate: ({
        ringDiam,
        ringTopDiam,
        ringHeight,
        ringThickness,
        isCovered,
      }) => {
        if (isCovered && ringThickness >= ringHeight) {
          return `<= ${ringHeight}`
        }

        const diam = isDef(ringTopDiam) ? ringTopDiam : ringDiam
        if (ringThickness > diam / 2) {
          return `<= ${diam / 2}`
        }
      },
    },
    segmentLength: {
      type: 'number',
      title: 'arc linear length',
      minimum: 0,
      default: defaults.segmentLength,
      validate: ({ ringDiam, ringThickness, segmentLength }) => {
        if (segmentLength > ringDiam - 2 * ringThickness) {
          return `<= ${ringDiam - 2 * ringThickness}`
        }
      },
    },
  }

  ringSegment.bounds = ({ ringDiam, segmentLength, ringHeight }) => {
    const segmentHeight = getSegmentHeight(ringDiam, segmentLength)

    return {
      min: [-segmentHeight, -segmentLength / 2, -ringHeight / 2],
      max: [segmentHeight, segmentLength / 2, ringHeight / 2],
    }
  }

  return ringSegment
}
