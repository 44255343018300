"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _resolveengines = _interopRequireDefault(require("./resolveengines"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/*
 * This contains the triple for loop for any expression evaluation.
 */
var walk = function walk(engine, rasterGrid) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  /*
   * Resolve dependencies from far to near.
   * - Apis with dependency are resolved with the dependency, or
   * - if they have no own implementation, replaced with dependency.
   */
  var api = engine(rasterGrid, options);
  var resolvedApi = (0, _resolveengines.default)(api, rasterGrid);
  var dim = resolvedApi.walkDimensions();

  for (var slice = 0; slice < dim.slices; slice++) {
    for (var row = 0; row < dim.rows; row++) {
      for (var column = 0; column < dim.columns; column++) {
        resolvedApi.map({
          column: column,
          row: row,
          slice: slice
        });
      }
    }
  }

  return resolvedApi.result();
};

var _default = walk;
exports.default = _default;