"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

/*
 * generalSample is an expression for both
 *  - sampling with average8=false, gridOperands=false, and
 *  - rasterizing with average8=true, gridOperands=true
 */
var generalSample = function generalSample(_ref) {
  var grid = _ref.grid,
      operands = _ref.operands,
      _ref$average = _ref.average8,
      average8 = _ref$average === void 0 ? false : _ref$average,
      _ref$gridOperands = _ref.gridOperands,
      gridOperands = _ref$gridOperands === void 0 ? false : _ref$gridOperands;

  var evaluate = function evaluate(operand, gridPoint) {
    return operand(gridOperands ? gridPoint : grid.gridToPoint(gridPoint));
  };

  return function (_ref2) {
    var columns = _ref2.columns,
        rows = _ref2.rows,
        slices = _ref2.slices;
    var rasterOffset = grid.offset({
      columns: columns,
      rows: rows,
      slices: slices
    }); // [0, 0, 0] is embedded in code.

    var corners = [[0, 0, 1], [0, 1, 0], [0, 1, 1], [1, 0, 0], [1, 0, 1], [1, 1, 0], [1, 1, 1]];
    var value = evaluate(operands[0], {
      columns: columns,
      rows: rows,
      slices: slices
    });

    if (average8) {
      // Average over 8 neighoring samples.
      value += corners.reduce(function (prev, corner) {
        return prev + evaluate(operands[0], {
          columns: columns + corner[2],
          rows: rows + corner[1],
          slices: slices + corner[0]
        });
      }, 0);
      value *= 0.125;
    }

    return value;
  };
};

var _default = generalSample;
exports.default = _default;