import createSupportedRing from './createSupportedRing'

const stand = createSupportedRing({
  lidDiam: 100,
  lidHeight: 4,
  ringOffset: 0,
  ringHeight: 8,
  ringThickness: 9,
})

stand.label = 'stand'

export default stand
