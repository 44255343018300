import createNotchedRing from './createNotchedRing'

const defaultHoleD = 8
const defaultRingHeight = 16

const notchedRing = createNotchedRing({
  ringDiam: 60,
  ringTopDiam: 40,
  ringHeight: defaultRingHeight,
  ringThickness: 5,
  notchDiam: defaultHoleD,
  notchIsRound: true,
  // center that notch
  notchOffset: (defaultRingHeight - defaultHoleD) / 2,
  isCovered: true,
  coverHoleDiam: defaultHoleD,
})

notchedRing.label = 'ring with notch'

const {
  ringDiam,
  ringTopDiam,
  ringThickness,
  ringHeight,
  notchDiam,
  notchOffset,
} = notchedRing.params

notchedRing.params = {
  ringDiam,
  ringTopDiam,
  ringThickness,
  ringHeight,
  notchDiam,
  notchOffset,
}

export default notchedRing
