export default function createSupportedRing(defaults) {
  function suppRing(
    {
      lidDiam = defaults.lidDiam,
      lidHeight = defaults.lidHeight,
      ringOffset = defaults.ringOffset,
      ringHeight = defaults.ringHeight,
      ringThickness = defaults.ringThickness,
    },
    shapes
  ) {
    const ringDiam = lidDiam - 2 * ringOffset

    const lidShape = shapes.cylinder({
      radius: lidDiam / 2,
      height: lidHeight,
      origin: [0, 0, lidHeight / 2],
    })
    const totalHeight = ringHeight + lidHeight
    const ringOuter = shapes.cylinder({
      radius: ringDiam / 2,
      height: totalHeight,
      origin: [0, 0, totalHeight / 2],
    })

    const ringInner = shapes.cylinder({
      radius: ringDiam / 2 - ringThickness,
      height: totalHeight,
      origin: [0, 0, totalHeight / 2],
    })

    const ring =
      ringDiam / 2 > ringThickness
        ? shapes.opDifference({ operands: [ringOuter, ringInner] })
        : ringOuter

    return shapes.opUnion({ operands: [lidShape, ring] })
  }

  suppRing.bounds = ({ lidDiam, lidHeight, ringOffset, ringHeight }) => {
    const ringDiam = lidDiam - 2 * ringOffset
    const r = Math.max(lidDiam, ringDiam) / 2
    const totalHeight = lidHeight + ringHeight

    return { min: [-r, -r, 0], max: [r, r, totalHeight] }
  }

  suppRing.params = {
    lidDiam: {
      type: 'number',
      title: 'lid diameter',
      exclusiveMinimum: 0,
      default: defaults.lidDiam,
    },
    lidHeight: {
      title: 'lid height',
      type: 'number',
      exclusiveMinimum: 0,
      default: 4,
    },
    ringOffset: {
      type: 'number',
      title: 'ring offset',
      minimum: 0,
      default: defaults.ringOffset,
      validate: ({ lidDiam, ringOffset, ringThickness }) => {
        if (ringOffset > lidDiam / 2 - ringThickness) {
          return `<= ${lidDiam / 2 - ringThickness}`
        }
      },
    },
    ringHeight: {
      type: 'number',
      title: 'ring height',
      minimum: 0,
      default: defaults.ringHeight,
    },
    ringThickness: {
      type: 'number',
      title: 'ring thickness',
      exclusiveMinimum: 0,
      default: defaults.ringThickness,
    },
  }

  return suppRing
}
