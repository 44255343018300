"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vector = _interopRequireDefault(require("../vector3"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/*
 * Platonic primitives.
 */
var expressions = {};
var ns = [new _vector.default(1.0, 0.0, 0.0), new _vector.default(0.0, 1.0, 0.0), new _vector.default(0.0, 0.0, 1.0), new _vector.default(0.577, 0.577, 0.577), new _vector.default(-0.577, 0.577, 0.577), new _vector.default(0.577, -0.577, 0.577), new _vector.default(0.577, 0.577, -0.577), new _vector.default(0.0, 0.357, 0.934), new _vector.default(0.0, -0.357, 0.934), new _vector.default(0.934, 0.0, 0.357), new _vector.default(-0.934, 0.0, 0.357), new _vector.default(0.357, 0.934, 0.0), new _vector.default(-0.357, 0.934, 0.0), new _vector.default(0.0, 0.851, 0.526), new _vector.default(0.0, -0.851, 0.526), new _vector.default(0.526, 0.0, 0.851), new _vector.default(-0.526, 0.0, 0.851), new _vector.default(0.851, 0.526, 0.0), new _vector.default(-0.851, 0.526, 0.0)]; // Calculation for exact platonic.

var lInfNorm = function lInfNorm(point, ns) {
  return Math.max.apply(null, ns.map(function (n) {
    return Math.abs(point.dot(n));
  }));
}; // Calculation for approximated, smooth platonic.


var sumPowAbsDot = function sumPowAbsDot(point, ns, exponent) {
  return ns.reduce(function (pV, n) {
    return pV + powAbsDot(point, n, exponent);
  }, 0);
};

var powAbsDot = function powAbsDot(point, n, exponent) {
  return Math.pow(Math.abs(point.dot(n)), exponent);
};
/*
 * If exponent is >0, use a smooth platonic approximation.
 */


var platonic = function platonic(ns) {
  return function (_ref) {
    var _ref$exponent = _ref.exponent,
        exponent = _ref$exponent === void 0 ? 0 : _ref$exponent,
        _ref$radius = _ref.radius,
        radius = _ref$radius === void 0 ? 1 : _ref$radius;
    return function (point) {
      return exponent ? Math.pow(sumPowAbsDot(point, ns, exponent), 1 / exponent) - radius : lInfNorm(point, ns) - radius;
    };
  };
};

expressions.octahedron = platonic(ns.slice(3, 7));
expressions.dodecahedron = platonic(ns.slice(13, 19));
expressions.icosahedron = platonic(ns.slice(3, 13));
expressions.toctahedron = platonic(ns.slice(0, 7));
expressions.ticosahedron = platonic(ns.slice(3, 19));
var _default = expressions;
exports.default = _default;