"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _three = require("three");

var _vector3spherical = _interopRequireDefault(require("./vector3spherical"));

var _vector3modulo = _interopRequireDefault(require("./vector3modulo"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

(0, _vector3spherical.default)(_three.Vector3); // Modulo depents on spherical.

(0, _vector3modulo.default)(_three.Vector3);
var _default = _three.Vector3;
exports.default = _default;