export default function createDoubleQuadSpacer(defaults) {
  const doubleQuad = (
    {
      totalHeight = defaults.totalHeight,
      baseLength = defaults.baseLength,
      baseWidth = defaults.baseWidth,
      knobLength = defaults.knobLength,
      knobWidth = defaults.knobWidth,
      knobHeight = defaults.knobHeight,
      holeDiam = defaults.holeDiam,
    },
    shapes
  ) => {
    const baseHeight = totalHeight - knobHeight

    const quad = shapes.box({
      dimensions: [baseLength, baseWidth, baseHeight],
      origin: [0, 0, baseHeight / 2],
    })

    const knob = shapes.box({
      dimensions: [knobLength, knobWidth, totalHeight],
      origin: [0, 0, totalHeight / 2],
    })

    const base = knobHeight ? shapes.opUnion({ operands: [quad, knob] }) : quad

    const hole = shapes.cylinder({
      radius: holeDiam / 2,
      height: totalHeight,
      origin: [0, 0, totalHeight / 2],
    })

    return holeDiam ? shapes.opDifference({ operands: [base, hole] }) : base
  }

  doubleQuad.params = {
    baseLength: {
      title: 'base length',
      type: 'number',
      exclusiveMinimum: 0,
      default: defaults.baseLength,
    },
    baseWidth: {
      type: 'number',
      title: 'base width',
      exclusiveMinimum: 0,
      default: defaults.baseWidth,
    },
    totalHeight: {
      type: 'number',
      title: 'total height',
      exclusiveMinimum: 0,
      default: defaults.totalHeight,
    },
    knobLength: {
      title: 'knob length',
      type: 'number',
      exclusiveMinimum: 0,
      default: defaults.knobLength,
    },
    knobWidth: {
      type: 'number',
      title: 'knob width',
      exclusiveMinimum: 0,
      default: defaults.knobWidth,
    },
    knobHeight: {
      type: 'number',
      title: 'knob height',
      exclusiveMinimum: 0,
      default: defaults.knobHeight,
      validate: ({ totalHeight, knobHeight }) => {
        if (knobHeight >= totalHeight) {
          return `< ${totalHeight}`
        }
      },
    },
    holeDiam: {
      type: 'number',
      title: 'hole diameter',
      exclusiveMinimum: 0,
      default: defaults.holeDiam,
    },
  }
  doubleQuad.bounds = ({
    totalHeight,
    baseLength,
    baseWidth,
    knobLength = 0,
    knobWidth = 0,
    knobHeight = 0,
  }) => {
    const l2 = Math.max(baseLength, knobLength) / 2
    const w2 = Math.max(baseWidth, knobWidth) / 2

    return { min: [-l2, -w2, 0], max: [l2, w2, totalHeight] }
  }

  return doubleQuad
}
