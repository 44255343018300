"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toStl = toStl;
exports.toStlIterator = toStlIterator;

var _three = require("three");

var vector = new _three.Vector3();

function exportFace(geometry, face) {
  var output = '';
  vector.copy(face.normal).normalize();
  output += "\tfacet normal ".concat(vector.x, " ").concat(vector.y, " ").concat(vector.z, "\n");
  output += '\t\touter loop\n';
  var indices = [face.a, face.b, face.c];

  for (var j = 0; j < 3; j++) {
    var vertexIndex = indices[j];

    if (geometry.skinIndices.length === 0) {
      vector.copy(geometry.vertices[vertexIndex]);
      output += "\t\t\tvertex ".concat(vector.x, " ").concat(vector.y, " ").concat(vector.z, "\n");
    }
  }

  output += '\t\tendloop\n';
  output += '\tendfacet\n';
  return output;
}

function toStl(geometry) {
  var output = 'solid exported\n';
  var faces = geometry.faces;

  for (var i = 0, l = faces.length; i < l; i++) {
    var face = faces[i];
    output += exportFace(geometry, face);
  }

  output += 'endsolid exported\n';
  return output;
}

function toStlIterator(_ref) {
  var geometry = _ref.geometry,
      _ref$facesPerIteratio = _ref.facesPerIteration,
      facesPerIteration = _ref$facesPerIteratio === void 0 ? 1000 : _ref$facesPerIteratio;
  var idx = -1;
  var length = geometry.faces.length;
  var iterator = {
    next: function nextChunk() {
      if (idx === length) {
        idx++;
        return {
          value: 'endsolid exported\n'
        };
      }

      if (idx > length) {
        return {
          done: true
        };
      }

      if (idx < 0) {
        idx++;
        return {
          value: 'solid exported\n'
        };
      }

      var maxIdx = Math.min(length - 1, idx + facesPerIteration);
      var value = ''; // One face at a time is pretty slow --> speed this up a bit.

      while (idx <= maxIdx) {
        value += exportFace(geometry, geometry.faces[idx++]);
      }

      return {
        value: value
      };
    }
  };
  return iterator;
}