import createNotchedRing from './createNotchedRing'

const notchedRing = createNotchedRing({
  ringDiam: 100,
  ringHeight: 20,
  ringThickness: 10,
  notchDiam: 6,
  notchWidth: 0,
  notchHeight: 0,
})

notchedRing.label = 'ring with notch'

const { ringDiam, ringThickness, ringHeight, notchDiam } = notchedRing.params

notchedRing.params = { ringDiam, ringThickness, ringHeight, notchDiam }

export default notchedRing
