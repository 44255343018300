import { measurements, getTotal } from './measurements'
import miniwalls from './miniwalls'
import createTopNibbles from './topNibbles'
import bottomNibbles from './bottomNibbles'

const { hUnit, wall, duploNibbleHeight, duploNibbleZSpace } = measurements

const isCovered = false

export default function createDuploLow(defaults) {
  function curvedDuplo(params, shapes) {
    const widthUnits = params.widthUnits ?? defaults.widthUnits
    const lengthUnits = params.lengthUnits ?? defaults.lengthUnits
    const heightUnits = params.heightUnits ?? defaults.heightUnits
    const gyroidize = params.gyroidize ?? defaults.gyroidize

    const lwParams = { lengthUnits, widthUnits }

    const totalLength = getTotal(lengthUnits)
    const totalWidth = getTotal(widthUnits)
    const height = heightUnits * hUnit

    const baseBox = shapes.box({
      dimensions: [totalLength, totalWidth, height],
    })

    const baseCutout = shapes.box({
      dimensions: [
        totalLength - 2 * wall,
        totalWidth - 2 * wall,
        height - (isCovered ? wall : 0),
      ],
      origin: [0, 0, isCovered ? -wall / 2 : 0],
    })

    const baseShape = shapes.opDifference({ operands: [baseBox, baseCutout] })

    function createLowerFittings({ height = Infinity } = {}) {
      const params = { ...lwParams, height }

      const lowerFittings = []

      if (lengthUnits > 1 && widthUnits > 1) {
        lowerFittings.push(bottomNibbles(params, shapes))
      }

      lowerFittings.push(miniwalls(params, shapes))
      return lowerFittings
    }

    const lowerFittingsArr = createLowerFittings({ height: duploNibbleZSpace })
    const lower = shapes.opUnion({
      operands: lowerFittingsArr,
      origin: [0, 0, (duploNibbleZSpace - height) / 2],
    })
    const infLowerFittingsArr = createLowerFittings()

    // infinite as well.
    const infTopNibbles = createTopNibbles(lwParams, shapes)
    // final nibbles on top
    const topNibbles = createTopNibbles(
      { ...lwParams, height: duploNibbleHeight },
      shapes
    )

    const transition = shapes.opInterpolate({
      operands: [
        shapes.opUnion({ operands: infLowerFittingsArr }),
        infTopNibbles,
      ],
      height: duploNibbleZSpace / 2,
      evalAt: [0, 0],
      interval: height - duploNibbleZSpace,
      extend: 0.5,
      cut: true,
    })

    const posTopNibbles = shapes.opTranslate({
      operands: [topNibbles],
      offsets: [0, 0, (height + duploNibbleHeight) / 2],
    })

    const finalBase = gyroidize
      ? shapes.opGyroidize({
          operands: [baseShape],
          frequency: 2.5,
          amplitude: -1,
        })
      : baseShape

    // return bottomNibbles({...lwParams, height: Infinity}, shapes)
    // return transCut
    return shapes.opUnion({
      operands: [finalBase, lower, transition, posTopNibbles],
    })
  }

  curvedDuplo.params = {
    lengthUnits: {
      type: 'number',
      title: 'length units',
      minimum: 1,
      default: defaults.lengthUnits,
    },
    widthUnits: {
      type: 'number',
      title: 'width units',
      minimum: 1,
      default: defaults.widthUnits,
    },
    heightUnits: {
      type: 'number',
      title: 'height units',
      // 0.3 for transition -> gives just roughly 0.5!
      // so not Math.round(duploNibbleZSpace / hUnit + 0.3 * 100) / 100,
      minimum: 0.5,
      default: defaults.heightUnits,
    },
    gyroidize: {
      type: 'boolean',
      title: 'material saver (needs final precision)',
      default: false,
    },
  }

  curvedDuplo.label = 'curved duplo'

  curvedDuplo.bounds = ({ lengthUnits, widthUnits, heightUnits }) => {
    const length = getTotal(lengthUnits)
    const width = getTotal(widthUnits)
    const height = heightUnits * hUnit

    return {
      min: [-length / 2, -width / 2, -height / 2],
      max: [length / 2, width / 2, height / 2 + duploNibbleHeight],
    }
  }

  return curvedDuplo
}
