import createDoubleQuadSpacer from './createDoubleQuadSpacer'

const quadSpacer = createDoubleQuadSpacer({
  baseLength: 60,
  baseWidth: 60,
  totalHeight: 30,
  knobLength: 0,
  knobWidth: 0,
  knobHeight: 0,
  holeDiam: 8,
})

quadSpacer.label = 'quad spacer'

const { knobLength, knobWidth, knobHeight, ...rest } = quadSpacer.params

quadSpacer.params = rest

export default quadSpacer
