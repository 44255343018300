"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = threeGeometry;

var _three = require("three");

var _abstractmarchingcubes = _interopRequireDefault(require("./abstractmarchingcubes"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/*
 * Create THREE geometry.
 */
function threeGeometry(_ref) {
  var raster = _ref.raster,
      grid = _ref.grid;
  var geometry = new _three.Geometry();
  var vertexIndex = 0;

  var addFace = function addFace(vlist) {
    geometry.vertices.push(vlist[0].clone());
    geometry.vertices.push(vlist[1].clone());
    geometry.vertices.push(vlist[2].clone());
    var face = new _three.Face3(vertexIndex, vertexIndex + 2, vertexIndex + 1);
    geometry.faces.push(face);
    geometry.faceVertexUvs[0].push([new _three.Vector2(0, 0), new _three.Vector2(0, 1), new _three.Vector2(1, 1)]);
    vertexIndex += 3;
  };

  return {
    // Adding our addFace implementation.
    map: function map(prevMap) {
      return function (gridPoint) {
        return prevMap(gridPoint)(addFace);
      };
    },
    result: function result(ignore) {
      return function () {
        geometry.computeFaceNormals();
        geometry.computeVertexNormals();
        geometry.computeBoundingBox(); // const bounds = [min]

        var _geometry$boundingBox = geometry.boundingBox,
            min = _geometry$boundingBox.min,
            max = _geometry$boundingBox.max;
        var bounding = [min.toArray(), max.toArray()];
        return {
          geometry: geometry,
          bounding: bounding
        };
      };
    },
    dependency: _abstractmarchingcubes.default
  };
}