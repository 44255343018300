/*
 * hook
 */

const hook = (
  {
    width,
    strength,
    toothHeight,
    toothAngle,
    bottomProtrudeLen,
    toothGap,
    topLength,
    backHeight,
  },
  shapes
) => {
  let currentOrigin = [0, 0, 0]
  const moveOrigin = (offset) => {
    for (let i = 0; i <= 2; i++) {
      currentOrigin[i] += offset[i]
    }
  }

  // AAAAh
  topLength = Math.max(topLength - strength, 0)
  backHeight = Math.max(backHeight - strength, 0)
  toothAngle = toothAngle - 90

  const data = [
    {
      //  z goes down for toothHeight
      pre: [0, 0, -(toothHeight + strength) / 2],
      dimensions: [width, strength, toothHeight + strength],
      rotate: {
        axis: [1, 0, 0],
        angleDeg: toothAngle,
        center: [0, 0, toothHeight / 2],
      },
      post: [0, -strength / 2, -(toothHeight + strength) / 2],
    },
    {
      // go sideways toothGap
      pre: [0, (2 * strength + toothGap - bottomProtrudeLen) / 2, strength / 2],
      dimensions: [
        width,
        2 * strength + toothGap + bottomProtrudeLen,
        strength,
      ],
      post: [
        0,
        (2 * strength + toothGap + bottomProtrudeLen) / 2,
        -strength / 2,
      ],
    },
    {
      // go up backHeight
      pre: [0, -strength / 2, (strength + backHeight) / 2],
      dimensions: [width, strength, strength + backHeight],
      post: [0, strength / 2, (strength + backHeight) / 2],
    },
    {
      // hookNose horizontal
      pre: [0, -(topLength + strength) / 2, -strength / 2],
      dimensions: [width, topLength + strength, strength],
      post: [0, 0, 0],
    },
  ]

  return shapes.opUnion({
    operands: data.map(({ pre, dimensions, rotate, post }) => {
      moveOrigin(pre)
      let shape = shapes.box({ dimensions /*origin: currentOrigin*/ })
      if (rotate) {
        shape = shapes.opRotate({ operands: [shape], ...rotate })
      }
      const final = shapes.opTranslate({
        operands: [shape],
        offsets: currentOrigin,
      })
      moveOrigin(post)
      return final
    }),
  })
}

hook.label = 'hook'

hook.params = {
  topLength: {
    title: 'top length',
    type: 'number',
    default: 40,
    minimum: 0,
  },
  backHeight: {
    type: 'number',
    title: 'back height',
    default: 60,
  },
  toothGap: {
    type: 'number',
    title: 'gap to tooth',
    exclusiveMinimum: 0,
    default: 5,
  },
  toothHeight: {
    type: 'number',
    title: 'tooth height',
    exclusiveMinimum: 0,
    default: 4,
  },
  toothAngle: {
    type: 'number',
    title: 'tooth angle',
    exclusiveMinimum: 0,
    exclusiveMaximum: 180,
    default: 90,
  },
  bottomProtrudeLen: {
    type: 'number',
    title: 'bottom length after tooth',
    minimum: 0,
    default: 0,
  },
  width: {
    title: 'hook width',
    type: 'number',
    exclusiveMinimum: 0,
    default: 60,
  },
  strength: {
    type: 'number',
    title: 'hook strength',
    exclusiveMinimum: 0,
    default: 10,
  },
}

// length: eigentlich breite
// strength: dicke sollte ok sein.
hook.bounds = ({
  width,
  strength,
  backHeight,
  bottomProtrudeLen,
  toothGap,
  toothHeight,
  topLength,
}) => {
  backHeight = Math.max(backHeight, strength)
  topLength = Math.max(topLength, strength)

  const ymax = 1.5 * strength + toothGap
  const ymin =
    ymax -
    strength -
    Math.max(1.5 * strength + toothGap + bottomProtrudeLen, topLength)
  const zmin = -toothHeight - strength
  const zmax = zmin + Math.max(backHeight, strength + toothHeight)

  return { min: [-width / 2, ymin, zmin], max: [width / 2, ymax, zmax] }
}

export default hook
