/*
 * Lid
 */
import createSupportedRing from './createSupportedRing'

const lid = createSupportedRing({
  lidDiam: 100,
  lidHeight: 4,
  ringOffset: 6,
  ringHeight: 3,
  ringThickness: 3,
})

lid.label = 'lid'

export default lid
