import createNotchedRing from './createNotchedRing'

const hollowStick = createNotchedRing({
  ringDiam: 8,
  ringHeight: 60,
  ringThickness: 2,
  notchDiam: 0,
  notchWidth: 0,
  notchHeight: 0,
})

hollowStick.label = 'hollow stick'

const { notchDiam, notchWidth, notchHeight, ...restParams } = hollowStick.params

hollowStick.params = restParams

export default hollowStick
