"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = add;
Object.defineProperty(exports, "addMiddlewares", {
  enumerable: true,
  get: function get() {
    return _addmiddleware.addMiddlewares;
  }
});

var _library = _interopRequireDefault(require("../library"));

var _addmiddleware = require("./addmiddleware");

var _util = require("../util");

var _flow = _interopRequireDefault(require("lodash/flow"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/*
 * Allow users to specify additional middleware.
 */

/*
 * library holds final expressions.
 */
var library = {};
/*
 * Given an expression and a library, return a modified expression with
 * access to the library.
 */

var injectLibrary = function injectLibrary(expression) {
  var withLibrary = function withLibrary(params) {
    return expression(params, library);
  };

  (0, _util.copyOwnProperties)(expression, withLibrary);
  return withLibrary;
};
/*
 * Add expression to library for use by other expressions, incl user defined.
 */


var addToLibrary = function addToLibrary(expression, label) {
  if (!label) {
    throw new Error('Expression lacks a .label property');
  }

  library[label] = expression;
};

var tweak = (0, _flow.default)([// expression(params, shape) --> expression(params)
injectLibrary, // custom params and point pre-processing.
_addmiddleware.injectMiddleware]);
/*
 * Main function:
 * Enrich expressions with middleware, and give them access to other shapes.
 */

function add(expression) {
  // Try to fall back on function.name if label not provided.
  var label = expression.label || expression.name;
  var result = tweak(expression);
  addToLibrary(result, label);
  return result;
} // Apply add to all builtin expressions.


Object.keys(_library.default).forEach(function (key) {
  add(_library.default[key]);
});