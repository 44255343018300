import { measurements, getTotal } from './measurements'
import miniwalls from './miniwalls'
import bottomNibbles from './bottomNibbles'

const { hUnit, wall, duploNibbleZSpace } = measurements

const isCoveredDefault = true

function getFinalHeight({ height, heightUnits }, defaults) {
  if (height == null && heightUnits == null && defaults.height == null) {
    throw new Error('duploLow requires either height or heightUnits param')
  }

  height = height ?? defaults.height
  heightUnits = heightUnits ?? defaults.heightUnits

  return height ?? heightUnits * hUnit
}

export default function createDuploLow(defaults) {
  function duploLow(params, shapes) {
    const finalParams = {
      widthUnits: params.widthUnits ?? defaults.widthUnits,
      lengthUnits: params.lengthUnits ?? defaults.lengthUnits,
      isCovered: params.isCovered ?? isCoveredDefault,
      height: getFinalHeight(
        { height: params.height, heightUnits: params.heightUnits },
        defaults
      ),
    }

    const { widthUnits, lengthUnits, isCovered, height } = finalParams

    const totalLength = getTotal(lengthUnits)
    const totalWidth = getTotal(widthUnits)

    const baseBox = shapes.box({
      dimensions: [totalLength, totalWidth, height],
    })

    const baseCutout = shapes.box({
      dimensions: [
        totalLength - 2 * wall,
        totalWidth - 2 * wall,
        height - (isCovered ? wall : 0),
      ],
      origin: [0, 0, isCovered ? -wall / 2 : 0],
    })

    const baseShape = shapes.opDifference({ operands: [baseBox, baseCutout] })

    const lowerFittings = []

    if (lengthUnits > 1 && widthUnits > 1) {
      lowerFittings.push(bottomNibbles(finalParams, shapes))
    }

    lowerFittings.push(miniwalls(finalParams, shapes))

    return shapes.opUnion({ operands: [baseShape, ...lowerFittings] })
  }

  duploLow.params = {
    lengthUnits: {
      type: 'number',
      title: 'length units',
      minimum: 1,
      default: defaults.lengthUnits,
    },
    widthUnits: {
      type: 'number',
      title: 'width units',
      minimum: 1,
      default: defaults.widthUnits,
    },
    height: {
      type: 'number',
      title: 'height',
      validate: ({ height, isCovered }) => {
        const minHeight = duploNibbleZSpace + isCovered ? wall : 0
        if (height < minHeight) {
          return `>= ${minHeight}`
        }
      },
      default: defaults.height,
    },
    isCovered: {
      type: 'boolean',
      title: 'cover',
      default: isCoveredDefault,
    },
  }

  duploLow.label = 'lower duplo'

  duploLow.bounds = ({ lengthUnits, widthUnits, ...rest }) => {
    const height = getFinalHeight(rest, defaults)

    // TODO: Every bounds should be completed with defaults...
    // Problem: if we SET something as defaults to avoid exposing it to user,
    // it won't be expose to bounds as a parameter..
    const length = getTotal(lengthUnits ?? defaults.lengthUnits)
    const width = getTotal(widthUnits ?? defaults.widthUnits)

    return {
      min: [-length / 2, -width / 2, -height / 2],
      max: [length / 2, width / 2, height / 2],
    }
  }

  return duploLow
}
