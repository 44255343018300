"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = copyOwnProperties;

function copyOwnProperties(sourceFn, targetFn) {
  if (typeof sourceFn !== 'function' || typeof targetFn !== 'function') {
    throw new Error('Must pass 2 functions');
  }

  for (var key in sourceFn) {
    if (sourceFn.hasOwnProperty(key)) {
      targetFn[key] = sourceFn[key];
    }
  }
}