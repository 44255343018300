"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vector = _interopRequireDefault(require("../vector3"));

var _three = require("three");

var _times = _interopRequireDefault(require("lodash/times"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

function _construct(Parent, args, Class) { if (isNativeReflectConstruct()) { _construct = Reflect.construct; } else { _construct = function _construct(Parent, args, Class) { var a = [null]; a.push.apply(a, args); var Constructor = Function.bind.apply(Parent, a); var instance = new Constructor(); if (Class) _setPrototypeOf(instance, Class.prototype); return instance; }; } return _construct.apply(null, arguments); }

function _setPrototypeOf(o, p) { _setPrototypeOf = Object.setPrototypeOf || function _setPrototypeOf(o, p) { o.__proto__ = p; return o; }; return _setPrototypeOf(o, p); }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

var Grid = function Grid(_ref) {
  var dimensions = _ref.dimensions,
      bounds = _ref.bounds,
      origin = _ref.origin,
      extents = _ref.extents,
      spacings = _ref.spacings,
      _ref$directions = _ref.directions,
      directions = _ref$directions === void 0 ? {
    columns: [1, 0, 0],
    rows: [0, 1, 0],
    slices: [0, 0, 1]
  } : _ref$directions;

  if (dimensions) {
    var _dimensions = dimensions,
        columns = _dimensions.columns,
        rows = _dimensions.rows,
        slices = _dimensions.slices;

    if (columns !== Math.floor(columns) || rows !== Math.floor(rows) || slices !== Math.floor(slices)) {
      throw new Error('dimensions need to be integers.');
    }
  }

  if (!spacings && !dimensions) {
    dimensions = {
      columns: 100,
      rows: 100,
      slices: 100
    };
  } // Calculate dimensions from spacings or dimensions, then calculate spacings.


  if (spacings) {
    // spacings takes precendence over dimensions.
    if (dimensions) {
      console.warn('Both dimensions and spacings specified, using spacings.');
    }

    dimensions = {
      columns: extents.columns / spacings.columns,
      rows: extents.rows / spacings.rows,
      slices: extents.slices / spacings.slices
    };
  } // Now we have dimensions


  if (!bounds && !(origin && extents)) {
    throw new Error('Need to provide either bounds or origin and extents');
  }

  if (bounds) {
    if (origin || extents) {
      console.warn('Both bounds and origin or extents specified, using bounds.');
    }

    var min = bounds.min,
        max = bounds.max;
    var noMarginExtents = (0, _times.default)(3, function (i) {
      return max[i] - min[i];
    }); // TODO: Confirm that margin is needed as we are only running marching cubes on samples (middle of raster).

    var tmpDims = [dimensions.columns, dimensions.rows, dimensions.slices];
    var marginFactor = (0, _times.default)(3, function (i) {
      return (tmpDims[i] + 2) / tmpDims[i];
    });
    var marginExtents = (0, _times.default)(3, function (i) {
      return noMarginExtents[i] * marginFactor[i];
    });
    origin = min.map(function (low, i) {
      return low - (marginExtents[i] - noMarginExtents[i]) / 2;
    });
    extents = {
      columns: marginExtents[0],
      rows: marginExtents[1],
      slices: marginExtents[2]
    };
  } // These are the data uniquely describing the grid.


  this.origin = origin;
  this.extents = extents;
  this.dimensions = dimensions;
  this.directions = directions; // Cached calculations
  // ===================

  this._origin = _construct(_vector.default, _toConsumableArray(origin)); // directions matrix.

  var mDirections = new _three.Matrix3();
  mDirections.set.apply(mDirections, _toConsumableArray(directions.columns).concat(_toConsumableArray(directions.rows), _toConsumableArray(directions.slices))); // Input should be column, column, column..

  mDirections.transpose();
  this._mDirections = mDirections; // spacings (same as when provided in input)

  this._spacings = {
    columns: extents.columns / dimensions.columns,
    rows: extents.rows / dimensions.rows,
    slices: extents.slices / dimensions.slices
  }; // Unit vectors (used for both world and grid space).

  var unit1 = new _vector.default(1, 0, 0);
  var unit2 = new _vector.default(0, 1, 0);
  var unit3 = new _vector.default(0, 0, 1); // The grid base vectors expressed in standard base units.

  this._deltaColumn = unit1.clone().applyMatrix3(this._mDirections).multiplyScalar(this._spacings.columns);
  this._deltaRow = unit2.clone().applyMatrix3(this._mDirections).multiplyScalar(this._spacings.rows);
  this._deltaSlice = unit3.clone().applyMatrix3(this._mDirections).multiplyScalar(this._spacings.slices); // And their complement: Standard base unit vectors expressed in grid base.

  var gridBasis = new _three.Matrix3().getInverse(this._mDirections);
  this._gridBaseX = unit1.clone().applyMatrix3(gridBasis).divideScalar(this._spacings.columns);
  this._gridBaseY = unit2.clone().applyMatrix3(gridBasis).divideScalar(this._spacings.rows);
  this._gridBaseZ = unit3.clone().applyMatrix3(gridBasis).divideScalar(this._spacings.slices);
};
/*
 * increments to skip to neighboring corner
 * in column, row, slice direction.
 */


Grid.prototype.increments = function () {
  var d = this.dimensions;
  return {
    column: 1,
    row: d.columns,
    slice: d.rows * d.columns
  };
};
/*
 * The dimensions of the grid (which is one smaller in every direction than
 * the array we store the values in.)
 * Optionally we can add a modifier if our runner algorithm needs to e.g. walk
 * through dimensions - 1.
 * mod needs to be -1 in this case.
 */


Grid.prototype.getDimensions = function () {
  var mod = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var d = this.dimensions;
  return {
    columns: d.columns + mod,
    rows: d.rows + mod,
    slices: d.slices + mod
  };
};
/*
 * Return grid spacings.
 */


Grid.prototype.getSpacings = function () {
  return this._spacings;
};

Grid.prototype.getBounding = function () {
  var _this$getDimensions = this.getDimensions(),
      columns = _this$getDimensions.columns,
      rows = _this$getDimensions.rows,
      slices = _this$getDimensions.slices;

  var vColumns = this._deltaColumn.multiplyScalar(columns);

  var vRows = this._deltaRow.multiplyScalar(rows);

  var vSlices = this._deltaSlice.multiplyScalar(slices);

  return {
    origin: this._origin,
    end: this._origin.clone().add(vColumns).add(vRows).add(vSlices)
  };
};
/*
 * Number of grid nodes.
 */


Grid.prototype.size = function () {
  var d = this.dimensions;
  return d.columns * d.rows * d.slices;
};
/*
 * get internal array offset from column, row, slice
 */


Grid.prototype.offset = function (_ref2) {
  var columns = _ref2.columns,
      rows = _ref2.rows,
      slices = _ref2.slices;
  var inc = this.increments();
  return slices * inc.slice + rows * inc.row + columns * inc.column;
};
/*
 * Find grid-relative location corresponding to point.
 * TODO: suggest worldToGrid
 */


Grid.prototype.pointToGrid = function (point) {
  var local = point.clone().sub(this._origin);

  var gridPoint = this._gridBaseX.clone().multiplyScalar(local.x).add(this._gridBaseY.clone().multiplyScalar(local.y)).add(this._gridBaseZ.clone().multiplyScalar(local.z));

  return {
    column: gridPoint.x,
    row: gridPoint.y,
    slice: gridPoint.z
  };
};
/*
 * Like pointToGrid, but return nearest location on grid.
 */


Grid.prototype.pointToGridAligned = function (point) {
  var _this$pointToGrid = this.pointToGrid(point),
      column = _this$pointToGrid.column,
      row = _this$pointToGrid.row,
      slice = _this$pointToGrid.slice; // Align to nearest grid.


  column = Math.round(column);
  row = Math.round(row);
  slice = Math.round(slice);
  return {
    column: column,
    row: row,
    slice: slice
  };
};
/*
 * Find (x, y, z) corresponding to grid location.
 */


Grid.prototype.gridToPoint = function (_ref3) {
  var columns = _ref3.columns,
      rows = _ref3.rows,
      slices = _ref3.slices;
  return this._origin.clone().add(this._deltaColumn.clone().multiplyScalar(columns)).add(this._deltaRow.clone().multiplyScalar(rows)).add(this._deltaSlice.clone().multiplyScalar(slices));
};
/*
 * If grid is a raster grid, the point at a grid node corresponds to the
 * starting corner of a voxel.
 * Then this is to know the center of that voxel.
 */


Grid.prototype.gridToVoxelCenterPoint = function (_ref4) {
  var columns = _ref4.columns,
      rows = _ref4.rows,
      slices = _ref4.slices;
  return this.gridToPoint({
    columns: columns + 0.5,
    rows: rows + 0.5,
    slices: slices + 0.5
  });
};

var _default = Grid;
exports.default = _default;