import createNotchedRing from './createNotchedRing'

const slitRing = createNotchedRing({
  ringDiam: 50,
  ringHeight: 10,
  notchWidth: 15,
  // this makes the height be the rign height.
  notchHeight: null,
  notchDiam: null,
  ringThickness: null,
})

slitRing.label = 'grip'

const { ringDiam, ringHeight, notchWidth } = slitRing.params

slitRing.params = { ringDiam, ringHeight, notchWidth }

export default slitRing
