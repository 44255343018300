/*
 * hollow double stick
 */
import createTripleCylinder from './createTripleCylinder'

const tubeSeparator = createTripleCylinder({
  totalHeight: 100,
  innerDiam: 35,
  skirtHeight: 30,
  skirtDiam: 50,
  holeDiam: 28,
})

tubeSeparator.label = 'tube separator'

export default tubeSeparator
