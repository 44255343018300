"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _grid = _interopRequireDefault(require("./grid"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/*
 * Create a sampling grid from a raster grid.
 *
 * If grid defines a raster (i.e. voxels, 'o' in drawing), create a new
 * grid that is used for sampling around the raster.
 *
 * grid comparison layout:
 *
 * +-+-+-+-+
 * |o|o|o|o|
 * +-+-+-+-+
 * |o|o|o|o|
 * +-+-+-+-+
 *
 * where
 *
 * o: raster ('voxel values')
 * lines: samples (evaluated expression)
 *
 * Note both raster and sample grid have their origin at same left bottom '+'.
 * For sample grid, it's a corner to be evaluated.
 * For raster grid, it is the corner of one 'o' voxel.
 *
 * Using this, we can sample expression using a grid surrounding the raster grid
 * and which defines the sampling corners. We can then average over all 8
 * corners to get to the final raster (o) values (='voxel values') that match
 * this grids dimensions.
 */
var rasterToSamplingGrid = function rasterToSamplingGrid(grid) {
  var origin = grid.origin;

  var _dimensions = grid.getDimensions();

  var dimensions = {
    columns: _dimensions.columns + 1,
    rows: _dimensions.rows + 1,
    slices: _dimensions.slices + 1
  };

  var _spacings = grid.getSpacings();

  var extents = {
    columns: _spacings.columns * dimensions.columns,
    rows: _spacings.rows * dimensions.rows,
    slices: _spacings.slices * dimensions.slices
  };
  var directions = grid.directions;
  return new _grid.default({
    origin: origin,
    extents: extents,
    dimensions: dimensions,
    directions: directions
  });
};

var _default = rasterToSamplingGrid;
exports.default = _default;