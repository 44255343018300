"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _rasterize2 = _interopRequireDefault(require("../rasterize"));

var _abstractmarchingcubes = require("../engines/abstractmarchingcubes");

var _three = require("three");

var _fastMemoize = _interopRequireDefault(require("fast-memoize"));

var _expressions = _interopRequireDefault(require("../expressions"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Later keywords:
// octree
// active cells
// even-subdivision octree
// function Octant() {
//     function visit(marchingStep, { }) {
//         fn(valueAt())
//      }
//     return {
//         visit
//     }
//  }

/*
Some octant ideas:
either regular octant. Address with 0-1-4-0 etc., max ca 10 recursive levels
(if going from a 1000 grid to 1 grid)

Or:
Start - look at value - define cube size by radius aqrt2
while max(values) > value at all cube edges, adjust cube.

Then divide into quadrants:
0 - original part
1 - remainder rect in scan line
2 - remainder "aside", next to scanline
3 - remainder "below"

Then, addressing quadrants becomes:
Depth 0: (l, w, t)
Depth 1: (l, w, t, 0)(l', w', t')
--> each quad stores l/w/t (3 ints)
--> has 4 subquads * l/w/t each
Should be quite manageable.

Difference: octant is defined by its "address" per above.
Each quad needs to store a vector address each
-> The quad can be a leaf
-> if this is more optimal than octants, should take max 10 levels recursive.

Check again that non-classic MC. Why is it non-classic?

*/
// import walk from '../walk'
// OK We need expressions ONLY here.
// options: passed to the three-specific algo (e.g. file name for stlSave)
function threeGeometry(expression, params, gridOptions) {
  var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

  var _rasterize = (0, _rasterize2.default)((0, _expressions.default)(expression), params, gridOptions),
      grid = _rasterize.grid,
      raster = _rasterize.raster; // Our algorithm may lead us to same grid positions multiple times,
  // especially when walking all scan lines. memoizing helps a lot.


  var valueAt = (0, _fastMemoize.default)(function (columns, rows, slices) {
    return raster({
      columns: columns,
      rows: rows,
      slices: slices
    });
  });

  var pointAt = function pointAt(columns, rows, slices) {
    return grid.gridToVoxelCenterPoint({
      columns: columns,
      rows: rows,
      slices: slices
    });
  };

  var geometry, vertexIndex; // Start a new geometry.

  function prep() {
    geometry = new _three.Geometry();
    vertexIndex = 0;
  }

  function finalize() {
    // Finalize the geometry after all triangles are filled.
    geometry.computeFaceNormals();
    geometry.computeVertexNormals();
    geometry.computeBoundingBox(); // const bounds = [min]

    var _geometry$boundingBox = geometry.boundingBox,
        min = _geometry$boundingBox.min,
        max = _geometry$boundingBox.max;
    var bounding = [min.toArray(), max.toArray()];
    return {
      geometry: geometry,
      bounding: bounding
    };
  } // This is how we'll populate the geometry.


  function addFace(vlist) {
    geometry.vertices.push(vlist[0].clone());
    geometry.vertices.push(vlist[1].clone());
    geometry.vertices.push(vlist[2].clone());
    var face = new _three.Face3(vertexIndex, vertexIndex + 2, vertexIndex + 1);
    geometry.faces.push(face);
    geometry.faceVertexUvs[0].push([new _three.Vector2(0, 0), new _three.Vector2(0, 1), new _three.Vector2(1, 1)]);
    vertexIndex += 3;
  }

  var dim = grid.getDimensions(-1); // Running everything sync.

  /*
  prep()
  //   yeah could import just this part.
  // But: pass pointAt, valueAt so we can octree.
  for (let slice = 0; slice < dim.slices; slice++) {
    for (let row = 0; row < dim.rows; row++) {
      for (let column = 0; column < dim.columns; column++) {
        const gridPoint = { column, row, slice }
        marchingStep(gridPoint, { valueAt, pointAt, addFace })
      }
    }
  }
   return finalize()
  */

  var slice = -1;
  var iterator = {
    next: function nextChunk() {
      if (slice < 0) {
        prep();
        slice++;
        return {};
      }

      if (slice === dim.slices) {
        slice++;
        return {
          value: finalize(),
          done: true
        };
      }

      if (slice > dim.slices) {
        return {
          done: true
        };
      }

      for (var row = 0; row < dim.rows; row++) {
        for (var column = 0; column < dim.columns; column++) {
          var gridPoint = {
            column: column,
            row: row,
            slice: slice
          };
          (0, _abstractmarchingcubes.marchingStep)(gridPoint, {
            valueAt: valueAt,
            pointAt: pointAt,
            addFace: addFace
          });
        }
      }

      slice++;
      return {};
    }
  };
  return iterator;
}

var _default = {
  threeGeometry: threeGeometry
};
exports.default = _default;