"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _baseshapes = _interopRequireDefault(require("./baseshapes"));

var _operations = _interopRequireDefault(require("./operations"));

var _platonics = _interopRequireDefault(require("./platonics"));

var _fancy = _interopRequireDefault(require("./fancy"));

var _softice = _interopRequireDefault(require("./softice"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/*
 * Builtin expressions.
 */
var rawExpressions = Object.assign({}, _baseshapes.default, _platonics.default, _fancy.default, _softice.default, _operations.default); // Note, function.name is not available on IE.

var addNames = function addNames(literal) {
  Object.keys(literal).forEach(function (key) {
    literal[key].label = key;
  });
};

addNames(rawExpressions);
var _default = rawExpressions;
exports.default = _default;