"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

/*
 * Resolve dependencies between engines.
 * E.g. saveNrrd engine depends on asArray engine.
 */
var apiMethods = ['walkDimensions', 'map', 'result'];

var resolveEngines = function resolveEngines(currentApi, rasterGrid) {
  var dependApi;
  var options = {};
  var dep = currentApi.dependency;

  if (dep) {
    // Simple dependency is == engine, or else with options it is:
    // { engine, options }
    if (dep.engine && dep.options) {
      options = dep.options;
      dep = dep.engine;
    }

    dependApi = resolveEngines(dep(rasterGrid, options), rasterGrid);
  } // Resolve with 1-level dependency


  var _iteratorNormalCompletion = true;
  var _didIteratorError = false;
  var _iteratorError = undefined;

  try {
    for (var _iterator = apiMethods[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
      var name = _step.value;
      currentApi[name] = dependApi ? currentApi[name] ? currentApi[name](dependApi[name]) : dependApi[name] : currentApi[name];
    } // Finally, return api.

  } catch (err) {
    _didIteratorError = true;
    _iteratorError = err;
  } finally {
    try {
      if (!_iteratorNormalCompletion && _iterator.return != null) {
        _iterator.return();
      }
    } finally {
      if (_didIteratorError) {
        throw _iteratorError;
      }
    }
  }

  return currentApi;
};

var _default = resolveEngines;
exports.default = _default;