import createRingSegment from './createRingSegment'

const defaultRingHeight = 16

const ringSegment = createRingSegment({
  ringDiam: 60,
  ringHeight: defaultRingHeight,
  ringThickness: 5,
  segmentLength: 40,
})

ringSegment.label = 'partial ring'

const {
  ringDiam,
  ringThickness,
  ringHeight,
  segmentLength,
} = ringSegment.params

ringSegment.params = {
  ringDiam,
  ringThickness,
  ringHeight,
  segmentLength,
}

export default ringSegment
