import { measurements, getTotal } from './measurements'

const { raster, miniwall, duploNibbleRadius, gapBetweenBricks } = measurements

export default function miniwalls(
  { lengthUnits, widthUnits, height = Infinity },
  shapes
) {
  const totalLength = getTotal(lengthUnits)
  const totalWidth = getTotal(widthUnits)

  const lMiniwall = shapes.box({
    dimensions: [miniwall, totalWidth, height],
  })

  const wMiniwall = shapes.box({
    dimensions: [totalLength, miniwall, height],
  })

  const parts = []

  if (lengthUnits > 0) {
    const lMiniwalls = shapes.opTile({
      interval: [raster, totalWidth, height],
      repeats: [lengthUnits, 1, 1],
      operands: [lMiniwall],
      // origin: [(raster / 2) * (2 - lengthUnits), 0, 0],
      origin: [(raster - gapBetweenBricks - totalLength) / 2, 0, 0],
    })

    parts.push(lMiniwalls)
  }

  if (widthUnits > 0) {
    const wMiniwalls = shapes.opTile({
      interval: [totalLength, raster, height],
      repeats: [1, widthUnits, 1],
      operands: [wMiniwall],
      origin: [0, (raster - gapBetweenBricks - totalWidth) / 2, 0],
    })

    parts.push(wMiniwalls)
  }

  const miniwalls = shapes.opUnion({ operands: parts })
  const cutout = shapes.box({
    dimensions: [
      (lengthUnits - 1) * raster + duploNibbleRadius * 2,
      (widthUnits - 1) * raster + duploNibbleRadius * 2,
      height,
    ],
  })

  return shapes.opDifference({ operands: [miniwalls, cutout] })
}
