"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = vector3spherical;

function vector3spherical(Vector3) {
  Object.assign(Vector3.prototype, {
    /*
     * theta is 0 at [0, 0, 1] direction.
     */
    getSpherical: function getSpherical() {
      var radius = this.length();
      return {
        radius: radius,
        phi: Math.atan2(this.y, this.x),
        theta: radius ? Math.acos(this.z / radius) : 0
      };
    },
    fromSpherical: function fromSpherical(_ref) {
      var radius = _ref.radius,
          phi = _ref.phi,
          theta = _ref.theta;
      var sinThetaRadius = radius * Math.sin(theta);
      this.x = sinThetaRadius * Math.cos(phi);
      this.y = sinThetaRadius * Math.sin(phi);
      this.z = radius * Math.cos(theta);
    }
  });
}