const raster = 16
const gapBetweenBricks = 0.4 // 0.3 fdm
const duploBottomNibbleRadius = raster * (Math.sqrt(2) - 1)
const duploNibbleHeight = 4.5
// nibble radius: a square brick with size (dr x dr) turned by 45° on a neighbor nibble fits exactly
const duploNibbleRadius = raster * (1 - 1 / Math.sqrt(2)) + 0.1 // = 4.686+0.1

export const measurements = {
  raster,
  hUnit: 19.2,
  wall: 1.55,
  miniwall: 2.35, // real 1.35, FDM 2.35
  gapBetweenBricks,
  duploNibbleRadius,
  duploNibbleInnerRadius: duploNibbleRadius * 0.73,
  duploNibbleHeight,
  duploNibbleZSpace: duploNibbleHeight + 0.5,
  duploBottomNibbleRadius,
  duploBottomNibbleInnerRadius: duploBottomNibbleRadius - 1.3,
}

export function getTotal(units) {
  return units * raster - gapBetweenBricks
}
