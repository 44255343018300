"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = vector3modulo;

// https://web.archive.org/web/20090717035140if_/javascript.about.com/od/problemsolving/a/modulobug.htm
var mod = function mod(n, m) {
  return (n % m + m) % m;
};

var cMod = function cMod(n, m) {
  return {
    mod: mod(n + m / 2, m) - m / 2,
    index: Math.floor((n + m / 2) / m)
  };
};

function vector3modulo(Vector3) {
  Object.assign(Vector3.prototype, {
    /*
     * Sets this vector to its component-wise modulo with the parameter.
     * It's not a regular modulo, but a centered one:
     * A modulo component c leads to vectors from -c/2 to c/2.
     * Beyond limit, no modulo is taken
     */
    cModulo: function cModulo(modulo, limit) {
      var cellIndex = new Uint32Array(3);

      if (this.x >= 0 && this.x <= limit.x - modulo.x / 2) {
        var resX = cMod(this.x, modulo.x);
        cellIndex[0] = resX.index;
        this.setX(resX.mod);
      }

      if (this.y >= 0 && this.y <= limit.y - modulo.y / 2) {
        var resY = cMod(this.y, modulo.y);
        cellIndex[1] = resY.index;
        this.setY(resY.mod);
      }

      if (this.z >= 0 && this.z <= limit.z - modulo.z / 2) {
        var resZ = cMod(this.z, modulo.z);
        cellIndex[0] = resZ.index;
        this.setZ(resZ.mod);
      }

      return cellIndex;
    } // // TODO: spherical modulo.
    // test_sphereId: function() {
    //   const { radius, phi, theta } = this.getSpherical();
    //   this.fromSpherical({ radius, phi, theta });
    // }

  });
}