// Note: notchDiam and ringThickness don't NEED to be defined. The others, better define.
// But different notchWidth/notchHeight doesn't work with notchIsRound

function isDef(arg) {
  return typeof arg === 'number' || typeof arg === 'boolean'
}

/*
 * General ring with notch
 */
export default function createNotchedRing(defaults) {
  function notchedRing(
    {
      ringDiam = defaults.ringDiam,
      ringTopDiam = defaults.ringTopDiam,
      ringHeight = defaults.ringHeight,
      ringThickness = defaults.ringThickness,
      notchDiam = defaults.notchDiam,
      notchHeight = defaults.notchHeight,
      notchWidth = defaults.notchWidth,
      notchIsRound = defaults.notchIsRound,
      // idea: if create() function provides it, use it, else use 0
      notchOffset = defaults.notchOffset ?? 0,
      coverHoleDiam = defaults.coverHoleDiam ?? 0,
      // and we probably never display this one...
      isCovered = defaults.isCovered ?? false,
    } = {},
    shapes
  ) {
    // notchDiam defined has precedence
    // Nothing of these define? fall back to 0
    notchWidth = isDef(notchDiam) ? notchDiam : notchWidth
    // notchDiam has precedence. Then if not defined, make as high as ring.
    notchHeight = isDef(notchDiam)
      ? notchDiam
      : isDef(notchHeight)
      ? notchHeight
      : ringHeight

    // This becomes a disk with no hole if not defined.
    ringThickness = isDef(ringThickness) ? ringThickness : ringDiam / 2

    notchIsRound = isDef(notchIsRound) ? notchIsRound : false

    function ringRadii(thickness = 0) {
      const radii = { radius: ringDiam / 2 - thickness }

      if (isDef(ringTopDiam)) {
        radii.radius2 = ringTopDiam / 2 - thickness
      }

      return radii
    }

    const ringOuter = shapes.cylinder({
      ...ringRadii(),
      height: ringHeight,
    })
    let ringInner = shapes.cylinder({
      ...ringRadii(ringThickness),
      height: ringHeight,
    })

    if (isCovered) {
      ringInner = shapes.opCut({
        operands: [ringInner],
        point: [0, 0, ringHeight / 2 - ringThickness],
      })
    }

    // if it's a disk, nothing to subtract here.
    const ring =
      ringDiam / 2 > ringThickness
        ? shapes.opDifference({ operands: [ringOuter, ringInner] })
        : ringOuter

    const ringCoverHole = shapes.cylinder({
      radius: coverHoleDiam / 2,
      height: ringHeight,
    })

    const finalRing = !isCovered
      ? ring
      : shapes.opDifference({
          operands: [ring, ringCoverHole],
        })

    const maxDiam = Math.max(ringDiam, ringTopDiam ?? 0)
    const __roundNotch = shapes.cylinder({
      radius: notchWidth / 2,
      height: maxDiam / 2,
    })

    const _roundNotch = shapes.opRotate({
      operands: [__roundNotch],
      axis: [0, 1, 0],
      angle: Math.PI / 2,
    })

    const roundNotch = shapes.opTranslate({
      operands: [_roundNotch],
      offsets: [maxDiam / 4, 0, ringHeight / 2 - notchHeight / 2 - notchOffset],
    })

    const notch = notchIsRound
      ? roundNotch
      : shapes.box({
          dimensions: [ringDiam / 2, notchWidth, notchHeight],
          origin: [
            ringDiam / 4,
            0,
            ringHeight / 2 - notchHeight / 2 - notchOffset,
          ],
        })

    return notchWidth
      ? shapes.opDifference({ operands: [finalRing, notch] })
      : ring
  }

  notchedRing.params = {
    ringDiam: {
      type: 'number',
      title: isDef(defaults.ringBottomDiam)
        ? 'bottom diameter'
        : 'ring diameter',
      exclusiveMinimum: 0,
      default: defaults.ringDiam,
    },
    ringTopDiam: {
      type: 'number',
      title: 'top diameter',
      exclusiveMinimum: 0,
      default: defaults.ringTopDiam,
    },
    ringHeight: {
      type: 'number',
      title: 'ring height',
      exclusiveMinimum: 0,
      default: defaults.ringHeight,
    },
    ringThickness: {
      type: 'number',
      title: 'ring thickness',
      exclusiveMinimum: 0,
      default: defaults.ringThickness,
      validate: ({
        ringDiam,
        ringTopDiam,
        ringHeight,
        ringThickness,
        isCovered,
      }) => {
        if (isCovered && ringThickness >= ringHeight) {
          return `<= ${ringHeight}`
        }

        const diam = isDef(ringTopDiam) ? ringTopDiam : ringDiam
        if (ringThickness > diam / 2) {
          return `<= ${diam / 2}`
        }
      },
    },
    isCovered: {
      type: 'boolean',
      title: 'ring is covered',
      default: defaults.isCovered,
    },
    coverHoleDiam: {
      type: 'number',
      title: 'cover hole diameter',
      minimum: 0,
      default: defaults.coverHoleDiam,
      validate: ({ ringDiam, ringThickness, coverHoleDiam }) => {
        if (ringDiam - 2 * ringThickness < coverHoleDiam) {
          return `<= ${ringDiam - 2 * ringThickness}`
        }
      },
    },
    // Alternative to notchWidth, notchHeight
    notchDiam: {
      type: 'number',
      title: 'notch width',
      exclusiveMinimum: 0,
      default: defaults.notchDiam,
    },
    notchWidth: {
      type: 'number',
      title: 'notch width',
      exclusiveMinimum: 0,
      default: defaults.notchWidth,
    },
    notchHeight: {
      type: 'number',
      title: 'notch width',
      exclusiveMinimum: 0,
      default: defaults.notchHeight,
    },
    // for the Jan's example.
    notchIsRound: {
      type: 'boolean',
      title: 'notch is round',
      default: defaults.notchIsRound,
    },
    notchOffset: {
      type: 'number',
      title: 'notch top distance',
      default: defaults.notchOffset,
      exclusiveMinimum: 0,
      validate: ({
        ringHeight,
        notchDiam,
        notchWidth,
        notchHeight,
        notchOffset,
      }) => {
        const height = isDef(notchDiam)
          ? notchDiam
          : Math.max(notchWidth ?? 0, notchHeight ?? 0)
        if (ringHeight < height + notchOffset) {
          return `<= ${ringHeight - height}`
        }
      },
    },
  }

  notchedRing.bounds = ({ ringDiam, ringTopDiam, ringHeight }) => {
    const diam = Math.max(ringDiam, ringTopDiam ?? 0)

    return {
      min: [-diam / 2, -diam / 2, -ringHeight / 2],
      max: [diam / 2, diam / 2, ringHeight / 2],
    }
  }

  return notchedRing
}
