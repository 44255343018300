import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

/*
 * Shows how we render a static image in gatsby.
 * This will be a static asset - not loaded at runtime!
 */
export default function useDefaultThumbnails() {
  const data = useStaticQuery(graphql`
    query {
      itemImage: allFile(
        filter: {base: { regex: "/thumbnail-.{4}\\.png/" }},
        sort: {
          fields: [base]
          order: ASC
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fixed(width: 180, height: 180) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `)

  const resultImageMap = data.itemImage.edges.map((edge) => {
    return {
      fixed: edge.node.childImageSharp.fixed,
      // name: edge.node.name,
      id: edge.node.name.match(/thumbnail-(.{4})/)[1],
    }
  })

  // Return a dictionary of images.
  return Object.assign(
    {},
    ...resultImageMap.map(({ id, fixed }) => ({ [id]: fixed }))
  )
  // return resultImageMap
}
