const EPS = 0.00001

export default function createPlug(defaults) {
  function plug(
    {
      plugType = defaults.plugType,
      capDiam = defaults.capDiam,
      roundCapHeight = defaults.roundCapHeight,
      flatCapHeight = defaults.flatCapHeight,
      bodyDiam = defaults.bodyDiam,
      cutoutDiam = defaults.cutoutDiam,
      bodyHeight = defaults.bodyHeight,
      latchThickness = defaults.latchThickness,
      slitDepth = defaults.slitHeight,
      slitThickness = defaults.slitThickness,
    } = {},
    shapes
  ) {
    const roundCapTemplate = shapes.cylinder({
      radius: capDiam / 2,
      height: roundCapHeight + EPS,
      origin: [0, 0, bodyHeight + flatCapHeight + roundCapHeight / 2 - EPS / 2],
    })

    const roundCapSphere = shapes.ellipsoid({
      radius: [capDiam / 2, capDiam / 2, roundCapHeight],
      origin: [0, 0, bodyHeight + flatCapHeight],
    })

    const roundCap = shapes.opIntersection({
      operands: [roundCapTemplate, roundCapSphere],
    })

    const flatCap = shapes.cylinder({
      radius: capDiam / 2,
      height: flatCapHeight + EPS,
      origin: [0, 0, bodyHeight + flatCapHeight / 2 - EPS / 2],
    })

    const body = shapes.cylinder({
      radius: bodyDiam / 2,
      height: bodyHeight + EPS,
      origin: [0, 0, bodyHeight / 2 + EPS / 2],
    })

    const latch = shapes.torus({
      radius: bodyDiam / 2,
      tube: latchThickness,
      origin: [0, 0, latchThickness],
    })

    const cutout = shapes.cylinder({
      radius: cutoutDiam / 2,
      height: bodyHeight,
      origin: [0, 0, bodyHeight / 2],
    })

    const slitCyl = shapes.cylinder({
      radius: slitThickness / 2,
      height: bodyDiam + 2 * latchThickness,
    })
    // lay it down.
    const slitRot = shapes.opRotate({
      operands: [slitCyl],
      axis: [0, 1, 0],
      angle: Math.PI / 2,
    })

    const slit = shapes.opElongate({
      operands: [slitRot],
      vector: [0, 0, slitDepth * 2],
    })

    const mainPart = shapes.opUnion({ operands: [latch, body] })

    const differences = []
    if (cutoutDiam) {
      differences.push(cutout)
    }

    if (slitDepth && slitThickness) {
      differences.push(slit)
    }

    const mainFinal = differences.length
      ? shapes.opDifference({ operands: [mainPart, ...differences] })
      : mainPart

    const parts = [mainFinal]

    if (flatCapHeight) {
      parts.push(flatCap)
    }
    if (plugType === 'cap' && roundCapHeight) {
      parts.push(roundCap)
    }

    if (plugType === 'symmetric') {
      const mirrored = shapes.opMirror({
        operands: [mainFinal],
        normal: [0, 0, 1],
      })
      const mirroredMoved = shapes.opTranslate({
        operands: [mirrored],
        offsets: [0, 0, 2 * bodyHeight + flatCapHeight],
      })
      parts.push(mirroredMoved)
    }

    let final = shapes.opUnion({ operands: parts })

    return final
  }

  plug.params = {
    plugType: {
      title: 'Plug type',
      type: 'string',
      enum: ['cap', 'symmetric'],
      enumNames: ['Simple', 'Double'],
      default: defaults.plugType,
    },
    capDiam: {
      type: 'number',
      title: 'cap diameter',
      minimum: 0,
      default: defaults.capDiam,
      // Validate! not smaller than bodyDiam
    },
    roundCapHeight: {
      type: 'number',
      title: 'round cap height',
      minimum: 0,
      default: defaults.roundCapHeight,
    },
    flatCapHeight: {
      type: 'number',
      title: 'flat cap height',
      minimum: 0,
      default: defaults.flatCapHeight,
    },
    bodyDiam: {
      type: 'number',
      title: 'body diameter',
      exclusiveMinimum: 0,
      default: defaults.bodyDiam,
    },
    cutoutDiam: {
      type: 'number',
      title: 'cutout diameter',
      minimum: 0,
      default: defaults.cutoutDiam,
    },
    bodyHeight: {
      type: 'number',
      title: 'body height',
      exclusiveMinimum: 0,
      default: defaults.bodyHeight,
    },
    latchThickness: {
      type: 'number',
      title: 'latch thickness',
      minimum: 0,
      default: defaults.latchThickness,
    },
    slitDepth: {
      type: 'number',
      title: 'slit depth',
      minimum: 0,
      default: defaults.slitDepth,
    },
    slitThickness: {
      type: 'number',
      title: 'slit thickness',
      minimum: 0,
      default: defaults.slitThickness,
    },
  }

  plug.bounds = ({
    plugType,
    capDiam,
    roundCapHeight,
    flatCapHeight,
    bodyDiam,
    cutoutDiam,
    bodyHeight,
    latchThickness,
    slitDepth,
    slitThickness,
  }) => {
    const xy = Math.max(bodyDiam / 2 + latchThickness, capDiam / 2)
    let z = Math.max(
      latchThickness * 2,
      bodyHeight + flatCapHeight + roundCapHeight
    )
    if (plugType === 'symmetric') {
      z += bodyHeight
    }
    return { min: [-xy, -xy, 0], max: [xy, xy, z] }
  }

  return plug
}
