import createNotchedRing from './createNotchedRing'

const stick = createNotchedRing({
  ringDiam: 8,
  ringHeight: 60,
  notchDiam: 0,
  notchWidth: 0,
  notchHeight: 0,
  ringThickness: null,
})

stick.label = 'hollow stick'

const { ringDiam, ringHeight } = stick.params

stick.params = { ringDiam, ringHeight }

export default stick
