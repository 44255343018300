"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.registerRunner = exports.runners = void 0;

var _rasterize = _interopRequireDefault(require("../rasterize"));

var _walk = _interopRequireDefault(require("./walk"));

var _expressions = _interopRequireDefault(require("../expressions"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/*
 * Create functional runners from evaluator engines.
 */
// OK We need expressions ONLY here.
var runners = {}; // Adding new runners

exports.runners = runners;

var registerRunner = function registerRunner(engines) {
  Object.keys(engines).forEach(function (key) {
    runners[key] = function (expression, params, gridOptions) {
      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      return (0, _walk.default)(engines[key], (0, _rasterize.default)((0, _expressions.default)(expression), params, gridOptions), options);
    };
  });
};

exports.registerRunner = registerRunner;