/*
 * The reason this is not a create* function is because there is no
 * situation where a caller might provide parameter defaults, it's such a
 * specific part.
 */
import createBrio, { measurements as brioMeasurements } from './createBrio'
import {
  createLowerDuplo,
  measurements as duploMeasurements,
} from './createDuplo'
import { maxBounds, moveBounds, modifyBounds } from '@gigmade/cvg'

const backSustainerLength = 4
const frontSustainerLength = 2

// That's the straight box the brio sits on top of.
const platformThickness = 2
// That's the amount of non-interpolation at bottom.
const baseThickness = 2.5

const { duploNibbleZSpace } = duploMeasurements
const { wall } = duploMeasurements
const { partThickness: brioPartThickness } = brioMeasurements

// 1.5 factor is arbitrary, this should give us the transition..
const lowerDuploHeight = duploNibbleZSpace * 2 // instead of 10?

// OK leaving out defaults - will populate params from actuals.
// What we enter here will ONLY be useful for filling defaults in user parameters.
// OK we ALSO need to pass defaults if the function requires the param,
// because the bounds calc accepts only params exposed by user, and this could fail.
const brioShape = createBrio({ trackLength: 18.75 })
const lowerDuploShape = createLowerDuplo({
  lengthUnits: 2,
  widthUnits: 2,
  height: lowerDuploHeight,
  isCovered: false,
})

/*
 * The separate, outside piece going from overall bottom to brio bottom to
 * sustain brio tracks.
 */
function sustainer(
  { lBrio, wBrio, lDuplo, wDuplo, hDuplo, gyroidize },
  shapes
) {
  const topFilled = shapes.box({
    dimensions: [lBrio + backSustainerLength, wBrio, Infinity],
    origin: [-backSustainerLength / 2, 0, 0],
  })

  const topCutout = shapes.box({
    dimensions: [
      // TODO: 2 is the part thickness of brio
      lBrio - frontSustainerLength - brioPartThickness,
      wBrio - 2 * brioPartThickness,
      Infinity,
    ],
    origin: [
      -((backSustainerLength + frontSustainerLength) / 2 - brioPartThickness) /
        2,
      0,
      0,
    ],
  })

  const bottomFilled = shapes.box({
    dimensions: [lDuplo, wDuplo, Infinity],
  })

  const bottomCutout = shapes.box({
    dimensions: [lDuplo - 2 * wall, wDuplo - 2 * wall, Infinity],
  })

  const infiniteFill = shapes.opInterpolate({
    operands: [bottomFilled, topFilled],
    height: -platformThickness / 2,
    evalAt: [0, 0],
    interval: hDuplo - platformThickness,
  })

  const infiniteCutout = shapes.opInterpolate({
    operands: [bottomCutout, topCutout],
    height: -platformThickness / 2,
    evalAt: [0, 0],
    interval: hDuplo - platformThickness,
  })

  const infiniteShape = shapes.opDifference({
    operands: [infiniteFill, infiniteCutout],
  })

  const topCut = shapes.opCut({
    operands: [infiniteShape],
    point: [0, 0, hDuplo / 2],
  })

  const final = shapes.opCut({
    operands: [topCut],
    point: [0, 0, -hDuplo / 2],
  })

  return gyroidize
    ? shapes.opGyroidize({ operands: [final], frequency: 2.5, amplitude: -1 })
    : final
}

// This is how we can move/assemble parts.
function duploBrio(params, shapes) {
  const brio = brioShape(params, shapes)
  //   const brioBounds = brioShape.bounds(params)
  //   const brioLength = brioBounds.max[0] - brioBounds.min[0]

  const { lengthUnits, gyroidize } = params
  //   const duploParams = {
  //     // use defaults defined above otherwise
  //     // lengthUnits: brioLength < 25 ? 2 : 3,
  //     // TODO: for this, params needs to accept... params...
  //     lengthUnits: Math.round(brioLength / duploMeasurements.raster),
  //   }

  const lowerDuplo = lowerDuploShape({ lengthUnits }, shapes)

  const { min: minDuplo, max: maxDuplo } = lowerDuploShape.bounds(params)
  const [lDuplo, wDuplo, hDuplo] = maxDuplo.map((mx, i) => mx - minDuplo[i])

  const { min: minBrio, max: maxBrio } = brioShape.bounds(params)
  const [lBrio, wBrio, hBrio] = maxBrio.map((mx, i) => mx - minBrio[i])

  const platformTransition = sustainer(
    { lBrio, wBrio, lDuplo, wDuplo, hDuplo, gyroidize },
    shapes
  )

  const movedBrio = shapes.opTranslate({
    operands: [brio],
    offsets: [0, 0, hDuplo / 2 + hBrio / 2],
  })

  // Select height and interval such that interpolation is finished at z = hDuplo/2
  // where the two objects officially meet.

  const interpolated = shapes.opInterpolate({
    operands: [lowerDuplo, movedBrio],
    // As height is the center of the interpolation
    height: baseThickness / 2,
    // z height where we evaluate the expressions during interpolation.
    evalAt: [hDuplo / 2 - 5, hDuplo / 2 + 5],
    interval: hDuplo - baseThickness,
  })

  // return shapes.opUnion({ operands: [interpolated, hollowTransition] })
  // return platformTransition
  return shapes.opUnion({
    operands: [interpolated, platformTransition],
  })
}

duploBrio.label = 'duplobrio'

const { bounds: duploBounds, params: duploParams } = lowerDuploShape
const { bounds: brioBounds, params: brioParams } = brioShape

duploBrio.bounds = (params) => {
  const { min: minD, max: maxD } = lowerDuploShape.bounds(params)
  const hDuplo = maxD[2] - minD[2]

  const { min: minB, max: maxB } = brioShape.bounds(params)
  const hBrio = maxB[2] - minB[2]

  // That's for the sustainer
  const extendedBrioBounds = modifyBounds(brioBounds, ({ min, max }) => ({
    min: [min[0] - backSustainerLength, min[1], min[2]],
    max: [max[0] + frontSustainerLength, max[1], max[2]],
  }))

  return maxBounds(
    duploBounds,
    moveBounds(extendedBrioBounds, [0, 0, hDuplo / 2 + hBrio])
  )(params)
}

const { trackLength, neckLength } = brioParams
const { lengthUnits } = duploParams

duploBrio.params = {
  // Longer? middle lower duplo nibbles won't attach anywhere.
  trackLength: { ...trackLength, maximum: 32 },
  lengthUnits: {
    ...lengthUnits,
    // validate({ trackLength, lengthUnits }) {
    //   const desiredUnits = Math.round(trackLength / duploMeasurements.raster) + 1
    //   if (desiredUnits !== lengthUnits) {
    //     return `= ${desiredUnits}`
    //   }
    // },
  },
  neckLength,
  gyroidize: {
    type: 'boolean',
    title: 'material saver (needs final precision)',
    default: false,
  },
}

export default duploBrio
// export default brioShape
// export default lowerDuploShape
