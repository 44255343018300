"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vector = _interopRequireDefault(require("../vector3"));

var _three = require("three");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

function _construct(Parent, args, Class) { if (isNativeReflectConstruct()) { _construct = Reflect.construct; } else { _construct = function _construct(Parent, args, Class) { var a = [null]; a.push.apply(a, args); var Constructor = Function.bind.apply(Parent, a); var instance = new Constructor(); if (Class) _setPrototypeOf(instance, Class.prototype); return instance; }; } return _construct.apply(null, arguments); }

function _setPrototypeOf(o, p) { _setPrototypeOf = Object.setPrototypeOf || function _setPrototypeOf(o, p) { o.__proto__ = p; return o; }; return _setPrototypeOf(o, p); }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

var expressions = {};

expressions.sphere = function (_ref) {
  var _ref$radius = _ref.radius,
      radius = _ref$radius === void 0 ? 1 : _ref$radius;
  return function (point) {
    return point.length() - radius;
  };
}; // Equivalent to scaling a sphere.


expressions.ellipsoid = function (_ref2) {
  var _ref2$radius = _ref2.radius,
      radius = _ref2$radius === void 0 ? [3, 2, 1] : _ref2$radius;

  var vRadius = _construct(_vector.default, _toConsumableArray(radius));

  return function (point) {
    return (point.clone().divide(vRadius).length() - 1) * Math.min(Math.min(vRadius.x, vRadius.y), vRadius.z);
  };
};

expressions.box = function (_ref3) {
  var _ref3$dimensions = _ref3.dimensions,
      dimensions = _ref3$dimensions === void 0 ? [5, 5, 5] : _ref3$dimensions;
  // Make sure to only calculate stuff once, and not inside the point function.
  var d = dimensions;
  var halfDimensions = new _vector.default(d[0] / 2, d[1] / 2, d[2] / 2);
  return function (point) {
    return Math.max(Math.abs(point.x) - halfDimensions.x, Math.abs(point.y) - halfDimensions.y, Math.abs(point.z) - halfDimensions.z);
  };
};

expressions.cylinder = function (_ref4) {
  var _ref4$radius = _ref4.radius,
      radius = _ref4$radius === void 0 ? 4 : _ref4$radius,
      _ref4$radius2 = _ref4.radius2,
      radius2 = _ref4$radius2 === void 0 ? undefined : _ref4$radius2,
      _ref4$height = _ref4.height,
      height = _ref4$height === void 0 ? 2 : _ref4$height;

  if (typeof radius2 === 'undefined') {
    radius2 = radius;
  }

  var halfHeight = height * 0.5;
  var radiusFactor = (radius2 - radius) / height;
  return function (point) {
    var distance = Math.sqrt(Math.pow(point.x, 2) + Math.pow(point.y, 2));
    var scaledRadius = radius + ( // Guard for 0 or Infinity height (here! to avoid Infinity/0)
    height === 0 || height === Infinity ? 0 : (point.z + halfHeight) * radiusFactor);
    return Math.max(distance - scaledRadius, Math.abs(point.z) - halfHeight);
  };
};

expressions.torus = function (_ref5) {
  var _ref5$radius = _ref5.radius,
      radius = _ref5$radius === void 0 ? 4 : _ref5$radius,
      _ref5$tube = _ref5.tube,
      tube = _ref5$tube === void 0 ? 1 : _ref5$tube;
  return function (point) {
    var length = Math.sqrt(Math.pow(point.x, 2) + Math.pow(point.y, 2)) - radius;
    return Math.sqrt(Math.pow(point.z, 2) + Math.pow(length, 2)) - tube;
  };
};

expressions.tetrahedron = function (_ref6) {
  var _ref6$radius = _ref6.radius,
      radius = _ref6$radius === void 0 ? 1 : _ref6$radius;
  return function (point) {
    var a = Math.abs,
        sq2 = Math.sqrt(2);
    return function (point) {
      var x = point.x,
          y = point.y,
          z = point.z;
      var distance = a(a(a(x) + sq2 * y) + a(x) + sq2 * z) + a(a(x) + sq2 * y) + a(x);
      return distance - radius;
    };
  };
};

expressions.trapezoid = function (_ref7) {
  var _ref7$lowDimensions = _ref7.lowDimensions,
      lowDimensions = _ref7$lowDimensions === void 0 ? [20, 10] : _ref7$lowDimensions,
      _ref7$highDimensions = _ref7.highDimensions,
      highDimensions = _ref7$highDimensions === void 0 ? [15, 5] : _ref7$highDimensions,
      _ref7$height = _ref7.height,
      height = _ref7$height === void 0 ? 10 : _ref7$height;
  // Make sure to only calculate stuff once, and not inside the point function.
  var halfHeight = height / 2;
  var lowHalfDim = new _three.Vector2(lowDimensions[0] / 2, lowDimensions[1] / 2);
  var highHalfDim = new _three.Vector2(highDimensions[0] / 2, highDimensions[1] / 2);

  function _getHalfDimensionsXY(z) {
    // z0: from 0 to 1 inside trapezoid
    var z0 = (z + halfHeight) / height;
    return [z0 * highHalfDim.x + (1 - z0) * lowHalfDim.x, z0 * highHalfDim.y + (1 - z0) * lowHalfDim.y];
  } // gracefully handle height === 0


  var getHalfDimensionsXY = height === 0 ? function () {
    return [0, 0];
  } : _getHalfDimensionsXY;
  return function (point) {
    var halfDimensionsXY = getHalfDimensionsXY(point.z);
    return Math.max(Math.abs(point.x) - halfDimensionsXY[0], Math.abs(point.y) - halfDimensionsXY[1], Math.abs(point.z) - halfHeight);
  };
};

var _default = expressions;
exports.default = _default;