"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vector = _interopRequireDefault(require("../vector3"));

var _three = require("three");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Date.prototype.toString.call(Reflect.construct(Date, [], function () {})); return true; } catch (e) { return false; } }

function _construct(Parent, args, Class) { if (isNativeReflectConstruct()) { _construct = Reflect.construct; } else { _construct = function _construct(Parent, args, Class) { var a = [null]; a.push.apply(a, args); var Constructor = Function.bind.apply(Parent, a); var instance = new Constructor(); if (Class) _setPrototypeOf(instance, Class.prototype); return instance; }; } return _construct.apply(null, arguments); }

function _setPrototypeOf(o, p) { _setPrototypeOf = Object.setPrototypeOf || function _setPrototypeOf(o, p) { o.__proto__ = p; return o; }; return _setPrototypeOf(o, p); }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

var expressions = {};
var EPS = 0.00001;

function clamp(x, low, high) {
  return Math.min(high, Math.max(low, x));
}
/*
 * Use this to re-use expressions, passing more arguments.
 */


expressions.opIdentity = function (_ref) {
  var operands = _ref.operands;
  return function (point) {
    return operands[0](point);
  };
};
/*
 * Classical booleans
 */


expressions.opUnion = function (_ref2) {
  var operands = _ref2.operands;
  return function (point) {
    return Math.min.apply(Math, _toConsumableArray(operands.map(function (op) {
      return op(point);
    })));
  };
};

expressions.opDifference = function (_ref3) {
  var operands = _ref3.operands;
  var subtracts = operands.slice(1);

  var subOperands = function subOperands(point) {
    return subtracts.map(function (op) {
      return -op(point);
    });
  };

  return function (point) {
    return Math.max.apply(Math, [operands[0](point)].concat(_toConsumableArray(subOperands(point))));
  };
};

expressions.opIntersection = function (_ref4) {
  var operands = _ref4.operands;
  return function (point) {
    return Math.max.apply(Math, _toConsumableArray(operands.map(function (op) {
      return op(point);
    })));
  };
};
/*
 * Transforms
 */

/*
 * interpolate:
 * - height: center of the transition
 * - interval: centered interval around where the transition happens
 * - evalAt: in the interval, we can optionally fix the z heights at which
 *   to evaluate lower and upper expression. By default, will be fixed at
 *   interval lower and upper boundary.
 * - extend/extend2: enlarge the objects to interpolate continuously versus
 *   the center point "height" to have more overlap. (extend: linear enlarge,
 *   extend2: square)
 * - cut (boolean): outside the interval, do we end the shape or evaluate the operands?
 */


expressions.opInterpolate = function (_ref5) {
  var operands = _ref5.operands,
      _ref5$height = _ref5.height,
      height = _ref5$height === void 0 ? 0 : _ref5$height,
      evalAt = _ref5.evalAt,
      _ref5$interval = _ref5.interval,
      interval = _ref5$interval === void 0 ? 1 : _ref5$interval,
      _ref5$extend = _ref5.extend,
      extend = _ref5$extend === void 0 ? 0 : _ref5$extend,
      _ref5$extend2 = _ref5.extend2,
      extend2 = _ref5$extend2 === void 0 ? 0 : _ref5$extend2,
      _ref5$cut = _ref5.cut,
      cut = _ref5$cut === void 0 ? false : _ref5$cut;

  var _operands = _slicedToArray(operands, 2),
      lower = _operands[0],
      upper = _operands[1];

  var halfInterval = interval / 2;
  var evalL = evalAt ? evalAt[0] : height - halfInterval;
  var evalU = evalAt ? evalAt[1] : height + halfInterval;
  var localL = new _vector.default();
  var localU = new _vector.default();
  return function (point) {
    var d = point.z - height;

    if (d > halfInterval) {
      return cut ? d - halfInterval : upper(point);
    } else if (d < -halfInterval) {
      return cut ? -halfInterval - d : lower(point);
    } else {
      // Inside the interpolate interval
      // Target: localRadius goes from 0 - extend
      // Math.abs() goes from 0 - halfInterval
      var absD = halfInterval - Math.abs(d);
      var localRadius = extend * (absD / halfInterval); // TODO: '/' before '**' ?

      var localRadius2 = extend2 * (Math.pow(absD, 2) / Math.pow(halfInterval, 2));
      localL.copy(point);
      localL.z = evalL;
      localU.copy(point);
      localU.z = evalU;
      var alpha = (d + halfInterval) / interval;
      var value = alpha * upper(localU) + (1 - alpha) * lower(localL) - (localRadius + localRadius2);
      return cut ? Math.max(value, -absD) : value;
    }
  };
}; // Example image
// https://rodzill4.github.io/material-maker/doc/node_3d_sdf_operators_morph.html


expressions.opMix = function (_ref6) {
  var operands = _ref6.operands,
      _ref6$alpha = _ref6.alpha,
      alpha = _ref6$alpha === void 0 ? 0.5 : _ref6$alpha;
  var first = operands[0];
  var second = operands[1];
  return function (point) {
    return alpha * first(point) + (1 - alpha) * second(point);
  };
};

expressions.opScale = function (_ref7) {
  var _ref7$factors = _ref7.factors,
      factors = _ref7$factors === void 0 ? [0.5, 0.5, 0.5] : _ref7$factors,
      operands = _ref7.operands;

  var f = _construct(_vector.default, _toConsumableArray(factors));

  return function (point) {
    var samplePoint = point.clone().divide(f);
    return operands[0](samplePoint);
  };
};

expressions.opTranslate = function (_ref8) {
  var operands = _ref8.operands,
      _ref8$offsets = _ref8.offsets,
      offsets = _ref8$offsets === void 0 ? [0.5, 0.5, 0.5] : _ref8$offsets;

  var o = _construct(_vector.default, _toConsumableArray(offsets));

  return function (point) {
    var samplePoint = point.clone().sub(o);
    return operands[0](samplePoint);
  };
};

expressions.opMirror = function (_ref9) {
  var operands = _ref9.operands,
      _ref9$normal = _ref9.normal,
      normal = _ref9$normal === void 0 ? [1, 0, 0] : _ref9$normal;

  var norm = _construct(_vector.default, _toConsumableArray(normal));

  var samplePoint = new _vector.default();
  return function (point) {
    return operands[0](samplePoint.copy(point).reflect(norm));
  };
};

expressions.opRotate = function (_ref10) {
  var operands = _ref10.operands,
      _ref10$axis = _ref10.axis,
      axis = _ref10$axis === void 0 ? [0, 0, 1] : _ref10$axis,
      _ref10$center = _ref10.center,
      center = _ref10$center === void 0 ? [0, 0, 0] : _ref10$center,
      _ref10$angle = _ref10.angle,
      angle = _ref10$angle === void 0 ? Math.PI / 6 : _ref10$angle;

  var ax = _construct(_vector.default, _toConsumableArray(axis));

  var rotMatrix = new _three.Matrix4().makeRotationAxis(ax, angle);
  var moveTo = new _three.Matrix4().makeTranslation(-center[0], -center[1], -center[2]);
  var moveBack = new _three.Matrix4().makeTranslation(center[0], center[1], center[2]);
  var matrix = moveTo.clone().multiply(rotMatrix).multiply(moveBack);
  var samplePoint = new _vector.default();
  return function (point) {
    samplePoint.copy(point);
    samplePoint.applyMatrix4(matrix);
    return operands[0](samplePoint);
  };
};

expressions.opRound = function (_ref11) {
  var operands = _ref11.operands,
      _ref11$radius = _ref11.radius,
      radius = _ref11$radius === void 0 ? 3 : _ref11$radius;
  return function (point) {
    return operands[0](point) - radius;
  };
};

expressions.opTwist = function (_ref12) {
  var operands = _ref12.operands,
      _ref12$axis = _ref12.axis,
      axis = _ref12$axis === void 0 ? [0, 0, 1] : _ref12$axis,
      _ref12$angle = _ref12.angle,
      angle = _ref12$angle === void 0 ? Math.PI / 36 : _ref12$angle;
  var mat = new _three.Matrix4();

  var ax = _construct(_vector.default, _toConsumableArray(axis));

  return function (point) {
    // const matrix = mat.makeRotationAxis(ax, angle * point.dot(ax));
    mat.makeRotationAxis(ax, angle * point.dot(ax));
    var samplePoint = point.clone().applyMatrix4(mat);
    return operands[0](samplePoint);
  };
};

expressions.opBend = function (_ref13) {
  var operands = _ref13.operands,
      _ref13$anglePoint = _ref13.anglePoint,
      anglePoint = _ref13$anglePoint === void 0 ? [1, 0, 0] : _ref13$anglePoint,
      _ref13$axis = _ref13.axis,
      axis = _ref13$axis === void 0 ? [0, 1, 0] : _ref13$axis,
      _ref13$angle = _ref13.angle,
      angle = _ref13$angle === void 0 ? Math.PI / 9 : _ref13$angle;
  var mat = new _three.Matrix4();

  var anglePointV = _construct(_vector.default, _toConsumableArray(anglePoint));

  var direction = anglePointV.clone().normalize();
  var anglePointLength = anglePointV.length();

  var ax = _construct(_vector.default, _toConsumableArray(axis));

  return function (point) {
    // distance is measured along direction
    var distance = point.clone().dot(direction);
    var angleFactor = clamp(distance / anglePointLength, -1, 1);
    var currentAngle = angle * angleFactor;
    mat.makeRotationAxis(ax, currentAngle);
    var samplePoint = point.clone().applyMatrix4(mat);
    return operands[0](samplePoint);
  };
}; // Problem: this shear is based on sub, works always unlike the other


expressions.opShear = function (_ref14) {
  var operands = _ref14.operands,
      _ref14$anglePoint = _ref14.anglePoint,
      anglePoint = _ref14$anglePoint === void 0 ? [1, 0, 0] : _ref14$anglePoint,
      _ref14$headPoint = _ref14.headPoint,
      headPoint = _ref14$headPoint === void 0 ? [0, 0, 1] : _ref14$headPoint,
      _ref14$angle = _ref14.angle,
      angle = _ref14$angle === void 0 ? Math.PI / 9 : _ref14$angle;

  var anglePointV = _construct(_vector.default, _toConsumableArray(anglePoint));

  var direction = anglePointV.clone().normalize();
  var anglePointLength = anglePointV.length();

  var normal = _construct(_vector.default, _toConsumableArray(headPoint)).normalize();

  return function (point) {
    // distance is measured along direction
    var distance = point.clone().dot(direction);
    var angleFactor = clamp(distance / anglePointLength, 0, 1);
    var currentAngle = angle * angleFactor;
    var shear = normal.clone().multiplyScalar(distance * Math.sin(currentAngle));
    var samplePoint = point.clone().sub(shear);
    return operands[0](samplePoint);
  };
}; // Problem: this shear is based on scale, so only works for >0
// Also we're not using the headPoint's length.
// In consequence of sub(), we've accidentally made this a linear function.
// ...which we can use but it's not shear.


expressions.opShearBase = function (_ref15) {
  var operands = _ref15.operands,
      _ref15$anglePoint = _ref15.anglePoint,
      anglePoint = _ref15$anglePoint === void 0 ? [1, 0, 0] : _ref15$anglePoint,
      _ref15$headPoint = _ref15.headPoint,
      headPoint = _ref15$headPoint === void 0 ? [0, 0, 1] : _ref15$headPoint,
      _ref15$angle = _ref15.angle,
      angle = _ref15$angle === void 0 ? Math.PI / 9 : _ref15$angle;

  var anglePointV = _construct(_vector.default, _toConsumableArray(anglePoint));

  var direction = anglePointV.clone().normalize();
  var anglePointLength = anglePointV.length();

  var head = _construct(_vector.default, _toConsumableArray(headPoint));

  var normal = head.clone().normalize();
  return function (point) {
    var angleDistance = point.dot(direction);
    var headDistance = point.dot(normal);
    var angleFactor = clamp(angleDistance / anglePointLength, 0, 1);
    var currentAngle = angle * angleFactor;
    var deltaH = angleDistance * Math.sin(currentAngle);
    var shear = normal.clone() // handle division by 0, and also no shearing for points below 0
    // Factor 2 because the multiplication would work in + and - normal direction.
    .multiplyScalar(headDistance <= 0 ? 1 : (headDistance + deltaH * 2) / headDistance) // Putting constant 1 scale for other dimensions.
    .add(direction).add(normal.clone().cross(direction)); // const f = head.clone().multiplyScalar(headFactor)

    var samplePoint = point.clone().divide(shear);
    return operands[0](samplePoint);
  };
}; // An experiment


expressions.opBendBase = function (_ref16) {
  var operands = _ref16.operands,
      _ref16$direction = _ref16.direction,
      direction = _ref16$direction === void 0 ? [1, 0, 0] : _ref16$direction,
      _ref16$axis = _ref16.axis,
      axis = _ref16$axis === void 0 ? [0, 1, 0] : _ref16$axis,
      _ref16$angle = _ref16.angle,
      angle = _ref16$angle === void 0 ? Math.PI / 36 : _ref16$angle,
      _ref16$angleHeight = _ref16.angleHeight,
      angleHeight = _ref16$angleHeight === void 0 ? 5 : _ref16$angleHeight;
  var mat = new _three.Matrix4();

  var dir = _construct(_vector.default, _toConsumableArray(direction));

  var ax = _construct(_vector.default, _toConsumableArray(axis));

  var normal = dir.clone().cross(ax).normalize();
  return function (point) {
    var height = point.clone().dot(normal);
    var length = point.clone().dot(dir); // Clamp the angle to 0 at height 0, and `angle` at height angleHeight
    // const angleFactor = height / Math.min(angleHeight, height)

    var angleFactor = Math.max(0, Math.min(1, height / angleHeight));
    mat.makeRotationAxis(ax, angle * length * angleFactor);
    var samplePoint = point.clone().applyMatrix4(mat);
    return operands[0](samplePoint);
  };
};
/*
 * The second operand doesn't exceed the first in height.
 * This is broken in some ways. E.g. limited shape will re-appear if no z limit
 * but xy dims of reference shape get closer on higher z.
 */


expressions.opLimitBelow = function (_ref17) {
  var operands = _ref17.operands;

  var _operands2 = _slicedToArray(operands, 2),
      ref = _operands2[0],
      limited = _operands2[1];

  var samplePoint = new _vector.default();
  var eps = 0.00001;
  return function (point) {
    var result = ref(point); // inside material? then result counts

    if (result <= 0) {
      return result;
    }

    samplePoint.copy(point);
    samplePoint.z += eps;
    var test = ref(samplePoint); // Use a union if isBelow.

    return test <= result ? Math.min(result, limited(point)) : result;
  };
};
/*
 * Attention: this hollowing increases the shape's boundary size by thickness/2.
 */


expressions.opContour = function (_ref18) {
  var operands = _ref18.operands,
      thickness = _ref18.thickness;
  return function (point) {
    return Math.abs(operands[0](point)) - thickness / 2;
  };
};
/*
 * This hollowing results in the same outside contour.
 * This needs a precise sdf. See also
 * https://iquilezles.org/www/articles/interiordistance/interiordistance.htm
 */


expressions.opHollow = function (_ref19) {
  var operands = _ref19.operands,
      thickness = _ref19.thickness;
  return function (point) {
    return Math.abs(operands[0](point) + thickness / 2) - thickness / 2;
  };
};
/*
 * Trick if I know 2 points and some symmetry (e.g. plane constant along y):
 * - choose point1, point2 with same y
 * - pass point1 ( or 2) as "point"
 * - normal is (point1 - point2) x constant_direction (e.g. [0, 1, 0])
 */


expressions.opCut = function (_ref20) {
  var operands = _ref20.operands,
      _ref20$point = _ref20.point,
      point = _ref20$point === void 0 ? [0, 0, 1] : _ref20$point,
      normal = _ref20.normal;

  var vPoint = _construct(_vector.default, _toConsumableArray(point)); // By default, plane normal is in direction of point.


  var vNormal = normal ? _construct(_vector.default, _toConsumableArray(normal)) : vPoint.length() ? vPoint.clone() : new _vector.default(0, 0, 1);
  vNormal.normalize();
  var vDirection = new _vector.default();
  return function (evalPoint) {
    var height = vDirection.copy(evalPoint).sub(vPoint).dot(vNormal);
    return Math.max(operands[0](evalPoint), height);
  };
}; //
// Various
//


expressions.opCollapse = function (_ref21) {
  var operands = _ref21.operands,
      _ref21$offsets = _ref21.offsets,
      offsets = _ref21$offsets === void 0 ? [0.5, 0.5, 0.5] : _ref21$offsets;
  return function (point) {
    var x = point.x,
        y = point.y,
        z = point.z; // Math.sign(0) === 0

    var collapsed = new _vector.default(Math.sign(x || 1) * Math.max(0, Math.abs(x) + offsets[0]), Math.sign(y || 1) * Math.max(0, Math.abs(y) + offsets[1]), Math.sign(z || 1) * Math.max(0, Math.abs(z) + offsets[2]));
    return operands[0](collapsed);
  };
}; // Replications

/*
 * Replicate only an interval across space.
 * For now: take interval around a centered object, repeat from there,
 * return the original object still in its same place.
 * TODO: min, max, repeats -> define full window pos, not just interval.
 * NOTE: tile is faster than array.
 */


expressions.opTile = function (_ref22) {
  var _ref22$interval = _ref22.interval,
      interval = _ref22$interval === void 0 ? [2, 2, 2] : _ref22$interval,
      _ref22$repeats = _ref22.repeats,
      repeats = _ref22$repeats === void 0 ? [2, 3, 4] : _ref22$repeats,
      operands = _ref22.operands;

  var vRepeats = _construct(_vector.default, _toConsumableArray(repeats));

  var vInterval = _construct(_vector.default, _toConsumableArray(interval));

  var vRange = new _vector.default().multiplyVectors(vInterval, vRepeats);
  return function (point) {
    point.cModulo(vInterval, vRange);
    return operands[0](point);
  };
};
/*
 * Replicate both original and offset copies across all of space.
 * NOTE: Use tile if no overlapping repeats required.
 */


expressions.opArray = function (_ref23) {
  var _ref23$offsets = _ref23.offsets,
      offsets = _ref23$offsets === void 0 ? [0.3, 0.3, 0.3] : _ref23$offsets,
      _ref23$repeats = _ref23.repeats,
      repeats = _ref23$repeats === void 0 ? [1, 2, 3] : _ref23$repeats,
      operands = _ref23.operands;

  var vRepeats = _construct(_vector.default, _toConsumableArray(repeats));

  var vInterval = _construct(_vector.default, _toConsumableArray(offsets));

  return function (point) {
    var samplePoint = new _vector.default();
    var sample = Infinity;

    for (var slice = 0; slice < vRepeats.z; slice++) {
      samplePoint.setZ(point.z - slice * vInterval.z);

      for (var row = 0; row < vRepeats.y; row++) {
        samplePoint.setY(point.y - row * vInterval.y);

        for (var column = 0; column < vRepeats.x; column++) {
          samplePoint.setX(point.x - column * vInterval.x);
          sample = Math.min(sample, operands[0](samplePoint));
        }
      }
    }

    return sample;
  };
};

expressions.opBubble = function (_ref24) {
  var operands = _ref24.operands,
      _ref24$frequency = _ref24.frequency,
      frequency = _ref24$frequency === void 0 ? 1 : _ref24$frequency,
      _ref24$amplitude = _ref24.amplitude,
      amplitude = _ref24$amplitude === void 0 ? 1 : _ref24$amplitude;
  return function (point) {
    var bubble = Math.sin(frequency * point.x) * Math.sin(frequency * point.y) * Math.sin(frequency * point.z);
    var value = operands[0](point);
    return amplitude * bubble + value;
  };
};

expressions.opGyroidize = function (_ref25) {
  var operands = _ref25.operands,
      _ref25$frequency = _ref25.frequency,
      frequency = _ref25$frequency === void 0 ? 4 : _ref25$frequency,
      _ref25$amplitude = _ref25.amplitude,
      amplitude = _ref25$amplitude === void 0 ? 1 : _ref25$amplitude;
  return function (point) {
    var f = frequency;
    var value = operands[0](point);
    var gyr = Math.cos(f * point.x) * Math.sin(f * point.y) + Math.cos(f * point.y) * Math.sin(f * point.z) + Math.cos(f * point.z) * Math.sin(f * point.x);
    return Math.max(value, amplitude * gyr);
  };
};
/*
 * Takes 2 operands only.
 */


expressions.opCombineRadius = function (_ref26) {
  var operands = _ref26.operands,
      _ref26$radius = _ref26.radius,
      radius = _ref26$radius === void 0 ? 1 : _ref26$radius;
  return function (point) {
    var d1 = operands[0](point);
    var d2 = operands[1](point);
    var min = Math.min(d1, d2); // TODO: This has expression characteristics.

    if (d1 < radius && d2 < radius) {
      return Math.min(min, radius - Math.sqrt((radius - d1) * (radius - d1) + (radius - d2) * (radius - d2)));
    }

    return min;
  };
};

expressions.opCombineChamfer = function (_ref27) {
  var operands = _ref27.operands,
      _ref27$radius = _ref27.radius,
      radius = _ref27$radius === void 0 ? 1 : _ref27$radius;
  return function (point) {
    var d1 = operands[0](point);
    var d2 = operands[1](point);
    var min = Math.min(d1, d2);

    if (d1 < radius && d2 < radius) {
      return Math.min(min, d1 + d2 - radius);
    }

    return min;
  };
};

expressions.opCombineCollar = function (_ref28) {
  var operands = _ref28.operands,
      _ref28$radius = _ref28.radius,
      radius = _ref28$radius === void 0 ? 1 : _ref28$radius;
  return function (point) {
    var d1 = operands[0](point);
    var d2 = operands[1](point);
    var min = Math.min(d1, d2);

    if (d1 < radius && d2 < radius) {
      return Math.min(min, Math.max(d1, d2) - radius);
    }

    return min;
  };
};

expressions.opCombineSmooth = function (_ref29) {
  var operands = _ref29.operands,
      _ref29$factor = _ref29.factor,
      factor = _ref29$factor === void 0 ? 3 : _ref29$factor;
  return function (point) {
    var d1 = operands[0](point);
    var d2 = operands[1](point);
    var blend = Math.exp(-factor * d1) + Math.exp(-factor * d2);
    return -Math.log(blend) / factor;
  };
};
/*
 * See https://iquilezles.org/www/articles/distfunctions/distfunctions.htm
 * ..but that (2nd impl) didn't work as expected regarding the orthogonal direction.
 * Maybe it works for objects centered in [0, 0, 0].
 * Params
 * vector: direction and length of where we elongate
 * offset: amount along direction to set elongation center
 */


expressions.opElongate = function (_ref30) {
  var operands = _ref30.operands,
      _ref30$vector = _ref30.vector,
      vector = _ref30$vector === void 0 ? [1, 0, 0] : _ref30$vector,
      _ref30$offset = _ref30.offset,
      offset = _ref30$offset === void 0 ? 0 : _ref30$offset;

  var elongate = _construct(_vector.default, _toConsumableArray(vector));

  var elongateUnit = elongate.clone().normalize();
  var elongateHalfLength = elongate.length() / 2;
  var zero = new _vector.default(0, 0, 0); // TODO: for all clone(), check avoiding creating new vectors with copy

  var parallelPart = new _vector.default();
  var orthoPart = new _vector.default();
  var baseChange = new _vector.default();
  var local = new _vector.default();
  return function (point) {
    local.copy(point);

    if (offset) {
      baseChange.copy(elongateUnit).multiplyScalar(offset);
      local.sub(baseChange);
    }

    var parallelAmount = local.clone().dot(elongateUnit);
    parallelPart.copy(elongateUnit).multiplyScalar(parallelAmount);
    orthoPart.copy(local).sub(parallelPart); // Now if parallelPart is between -h and h, set to 0

    var reducedAmount = Math.abs(parallelAmount) - elongateHalfLength;

    if (reducedAmount < 0) {
      parallelPart.copy(zero);
    } else {
      parallelPart.copy(elongateUnit).multiplyScalar(Math.sign(parallelAmount) * reducedAmount);
    }

    var final = parallelPart.add(orthoPart);

    if (offset) {
      final.add(baseChange);
    }

    return operands[0](final);
  };
};
/*
 * After slice2, (x, y) plane reflects result. (2d expression)
 * Note: Math.max(dist - EPS, *) is just a safety measure to avoid unbounded
 * expressions.
 */


expressions.opSlice2 = function (_ref31) {
  var operands = _ref31.operands,
      _ref31$point = _ref31.point,
      point = _ref31$point === void 0 ? [0, 0, 0] : _ref31$point,
      _ref31$normal = _ref31.normal,
      normal = _ref31$normal === void 0 ? [0, 0, 1] : _ref31$normal;

  var pt = _construct(_vector.default, _toConsumableArray(point));

  var n = _construct(_vector.default, _toConsumableArray(normal));

  return function (point) {
    var projected = point.clone().projectOnPlane(n).sub(pt);
    var dist = Math.abs(point.dot(n));
    return Math.max(dist - EPS, operands[0](projected));
  };
};
/*
 * Extrude (x, y) along z.
 */


expressions.opExtrude = function (_ref32) {
  var operands = _ref32.operands,
      _ref32$height = _ref32.height,
      height = _ref32$height === void 0 ? 5 : _ref32$height;
  var halfHeight = height * 0.5;
  return function (point) {
    var pxy = point.clone().setZ(0);
    return Math.max(operands[0](pxy), Math.abs(point.z) - halfHeight);
  };
};

var _default = expressions;
exports.default = _default;