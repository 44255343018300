import createSupportedRing from './createSupportedRing'

const bezeledStand = createSupportedRing({
  lidDiam: 110,
  lidHeight: 10,
  ringOffset: 0,
  ringHeight: 10,
  ringThickness: 30,
})

bezeledStand.label = 'bezeled stand'

export default bezeledStand
