/*
 * hollow double stick
 */
import createTripleCylinder from './createTripleCylinder'

const hollowDoubleStick = createTripleCylinder({
  totalHeight: 100,
  innerDiam: 15,
  skirtHeight: 10,
  skirtDiam: 30,
  holeDiam: 8,
})

hollowDoubleStick.label = 'stick separator'

export default hollowDoubleStick
